import { Box, Grid, Typography } from "@mui/material";
import React from "react";

const MultiSelectView = ({ item, index }) => {
  return (
    <>
      <Box>
        <Box display={"flex"} gap={1} flexDirection={"row"}>
          <Typography
            style={{ color: "gray" }}
            fontSize={{ sx: "12px", xs: "14px" }}
          >
            {" "}
            <span
              style={{
                color: "black",
                fontSize: "18px",
              }}
            >
              {index}.{" "}
            </span>
            {item?.question}
          </Typography>
        </Box>
        <Grid container spacing={2}>
          {item?.answers && item?.answers?.length > 0 ? (
            item?.answers.map((ans, index) => (
              <Grid item xs={4}>
                <Box
                  sx={{
                    // border: "1px solid gray",
                    // borderRadius: "5px",
                    padding: "5px",
                  }}
                >
                  {" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;{ans}
                </Box>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Typography
                style={{ color: "gray" }}
                fontSize={{ sx: "12px", xs: "14px" }}
              >
                {" "}
                &nbsp;&nbsp;&nbsp;&nbsp;No Answer Given
              </Typography>
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default MultiSelectView;
