import React from "react";
import { Input } from "../../../components/Common";
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Typography,
  Tab,
  Tabs,
  Grid,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { FaBarcode } from "react-icons/fa";
import PropTypes from "prop-types";
import SkipLogic from "../SkipLogic";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Scan = ({
  index,
  forms,
  getPayload,
  setForms,
  formIndex,
  logicQuestionSelecteData,
  setLogicQuestionSelecteData,
  setDisplayLogicQuestionSelecteData,
  displaylogicQuestionSelecteData,
  skipLogicData,
  skipLogicCondition,
  setSkipLogicCondition,
  setDisplayLogicCondition,
  displayLogicCondition,
}) => {
  const handleChange = (event) => {
    let newArray = [...forms];
    let ids = uuidv4();

    newArray[formIndex] = {
      ...getPayload(event.target.value),
      type: event.target.value,
      index: index,
      ids: ids,
      logicQuestionType:
        event.target.value == "text" ? "textEmail" : event.target.value,
    };
    setForms(newArray);

    let newArray1 = [...logicQuestionSelecteData];
    newArray1[index] = {
      ...getPayload(event.target.value),
      type: event.target.value,
      index: index,
      ids: ids,
      name: forms[index].question,
      value: "",
    };
    setLogicQuestionSelecteData(newArray1);

    let newArray2 = [...displaylogicQuestionSelecteData];
    newArray2[index] = {
      type: event.target.value,
      index: index,
      ids: ids,
      name: forms[index].question,
      value: "",
    };

    setDisplayLogicQuestionSelecteData(newArray2);
  };
  const [value, setValue] = React.useState(0);

  const handleChange1 = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Stack
      sx={{
        display: "flex",
        justifyContent: "space-between",
        gap: "10px",
        flexDirection: "row",
        width: "100%",
        marginBottom: "20px",
      }}
    >
      <Stack sx={{ width: "100%" }}>
        <Stack
          sx={{
            backgroundColor: "white.main",
            borderRadius: "7px",
            padding: "20px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            height: "fit-content",
          }}
        >
          <Stack sx={{ display: "flex", flexDirection: "row", gap: "20px" }}>
            <Typography
              fontSize={{ xs: "10px", sm: "16px" }}
              fontWeight={500}
              mb={"2px"}
              width={"10px"}
              display={"flex"}
              flexDirection={"column"}
            >
              {formIndex + 1}.
            </Typography>
            <Stack
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <textarea
                placeholder="Please Enter your Question text here..."
                className="defaultinput textareaformcreation"
                onChange={(e) => {
                  let newArray = [...forms];
                  newArray[formIndex].question = e.target.value;
                  setForms(newArray);

                  let newArray1 = [...logicQuestionSelecteData];
                  newArray1[index].name = e.target.value;
                  setLogicQuestionSelecteData(newArray1);

                  let newArray2 = [...displaylogicQuestionSelecteData];
                  newArray2[index].name = e.target.value;
                  setDisplayLogicQuestionSelecteData(newArray2);
                }}
                value={forms[formIndex].question}
              />
              {/* <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  gap: "10px",
                  padding: "5px 20px",
                  backgroundColor: "#dbdbdb",
                  width: "fit-content",
                }}
              >
                <Box>
                  <FaBarcode />
                </Box>
                <Box>Scane Barcode</Box>
              </Stack> */}
              <Stack
                sx={{ display: "flex", flexDirection: "column" }}
                width={"100%"}
              >
                <Input
                  placeholder="Instruction Text"
                  onChange={(e) => {
                    let newArray = [...forms];
                    newArray[formIndex].intructinText = e.target.value;
                    setForms(newArray);
                  }}
                  value={forms[formIndex].intructinText}
                />
              </Stack>
            </Stack>
          </Stack>
          <Stack
            sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
            className="formcard"
          >
            <Stack className="formcreationmain">
              <Box className="headerrow">
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <FormControl>
                    <Select
                      value={forms[formIndex].type}
                      onChange={handleChange}
                      displayEmpty
                      sx={{
                        width: 300,
                        ".MuiSelect-select": {
                          padding: "10px",
                        },
                      }}
                    >
                      <MenuItem value="" sx={{ display: "flex", gap: "10px" }}>
                        Select Question Type
                      </MenuItem>
                      <MenuItem
                        value="text"
                        sx={{ display: "flex", gap: "10px" }}
                      >
                        Text
                      </MenuItem>
                      <MenuItem
                        value="multiSelect"
                        sx={{ display: "flex", gap: "10px" }}
                      >
                        {" "}
                        Multiple Choice
                      </MenuItem>
                      <MenuItem
                        value="scale"
                        sx={{ display: "flex", gap: "10px" }}
                      >
                        {" "}
                        Scale
                      </MenuItem>
                      <MenuItem
                        value="DropDown"
                        sx={{ display: "flex", gap: "10px" }}
                      >
                        {" "}
                        Drop Down
                      </MenuItem>
                      <MenuItem
                        value="Metrix"
                        sx={{ display: "flex", gap: "10px" }}
                      >
                        {" "}
                        Metrix
                      </MenuItem>
                      <MenuItem
                        value="Scan"
                        sx={{ display: "flex", gap: "10px" }}
                      >
                        Media Capture
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <Box sx={{ width: "fit-content" }}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <Tabs
                        value={value}
                        onChange={handleChange1}
                        aria-label="basic tabs example"
                      >
                        <Tab label="Options" {...a11yProps(0)} />
                        <Tab label="Logic" {...a11yProps(1)} />
                      </Tabs>
                    </Box>
                  </Box>
                </Stack>
                <label style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="checkbox"
                    style={{
                      marginRight: "10px",
                      height: "17px",
                      width: "17px",
                    }}
                    onChange={(e) => {
                      let newArray = [...forms];
                      newArray[formIndex].answerRequired = e.target.checked;
                      setForms(newArray);
                    }}
                    checked={forms[formIndex].answerRequired}
                  />
                  Scan Required
                </label>
              </Box>
            </Stack>
            <div className="verticalline"></div>
            {/* body */}
            <CustomTabPanel value={value} index={0}>
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "30px",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "start",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    justifyContent: "flex-start",
                    width: "100%",
                  }}
                >
                  <Typography
                    fontSize={{ xs: "10px", sm: "16px" }}
                    fontWeight={500}
                    mb={"2px"}
                  >
                    Type:
                  </Typography>
                  <FormControl sx={{ width: "250px", display: "flex" }}>
                    <Select
                      labelId="demo-simple  -select-label"
                      id="demo-simple-select"
                      displayEmpty
                      onChange={(e) => {
                        let newArray = [...forms];
                        newArray[formIndex].scanType = e.target.value;
                        setForms(newArray);
                      }}
                      value={forms[formIndex].scanType}
                    >
                      <MenuItem value="multiple">Multiple Scan</MenuItem>
                      <MenuItem value="single">Single Scan</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Stack>
              <Box>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="dynamic"
                    name="radio-buttons-group"
                    row
                  >
                    <FormControlLabel
                      value="scan"
                      control={<Radio />}
                      label="Scan"
                      onChange={(e) => {
                        let newArray = [...forms];
                        newArray[formIndex].captureType = e.target.value;

                        setForms(newArray);
                      }}
                      checked={forms[formIndex].captureType === "scan"}
                    />
                    <FormControlLabel
                      value="internalStorage"
                      control={<Radio />}
                      label="Internal Storage"
                      onChange={(e) => {
                        let newArray = [...forms];
                        newArray[formIndex].captureType = e.target.value;
                        setForms(newArray);
                      }}
                      checked={
                        forms[formIndex].captureType === "internalStorage"
                      }
                    />
                    <FormControlLabel
                      value="gallery"
                      control={<Radio />}
                      label="Camera"
                      onChange={(e) => {
                        let newArray = [...forms];
                        newArray[formIndex].captureType = e.target.value;
                        setForms(newArray);
                      }}
                      checked={forms[formIndex].captureType === "gallery"}
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Stack spacing={2}>
                <SkipLogic
                  logicQuestionSelecteData={logicQuestionSelecteData}
                  forms={forms}
                  setForms={setForms}
                  index={index}
                  setLogicQuestionSelecteData={setLogicQuestionSelecteData}
                  skipLogicData={skipLogicData}
                  skipLogicCondition={skipLogicCondition}
                  setSkipLogicCondition={setSkipLogicCondition}
                  displayLogicCondition={displayLogicCondition}
                  setDisplayLogicCondition={setDisplayLogicCondition}
                  displaylogicQuestionSelecteData={
                    displaylogicQuestionSelecteData
                  }
                  setDisplayLogicQuestionSelecteData={
                    setDisplayLogicQuestionSelecteData
                  }
                  formIndex={formIndex}
                />
              </Stack>
            </CustomTabPanel>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Scan;
