import React, { useState } from 'react';
import { FormControl, MenuItem, Select, Typography } from '@mui/material';

export default function CommonSelect(props) {
    const { selectHead, selectList, labelinput, style, value, className, onChange, formik, disabled, variant, placeholder } = props;


    return (
        <FormControl variant={variant} sx={{ width: '100%' }}>
            {labelinput && <Typography fontSize={{ xs: '10px', sm: '13px' }} fontWeight={500} mb={'2px'}>{labelinput}</Typography>}
            <Select
                value={value}
                onChange={onChange}
                labelId="demo-simple-select-error-label"
                id="demo-simple-select-error"
                fullWidth
                sx={style}
                placeholder={placeholder}
                className={className}
                error={formik && formik.touched[props.name] && formik.errors[props.name]}
                disabled={disabled}
            >
                {selectHead && <MenuItem value="SelectCustomer" >{selectHead}</MenuItem>}
                {selectList.map((data, i) => <MenuItem value={data.id || data?._id} key={i}>{data.name || data?.roleName
                }</MenuItem>)}
            </Select>
            {formik && formik.touched[props.name] && formik.errors[props.name] &&
                <Typography fontSize={'12px'} color={'#D32F2F'}>{formik.errors[props.name]}</Typography>
            }
        </FormControl>
    );
}