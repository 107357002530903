import React, { useEffect, useState } from "react";
import { Button, Heading, MenuButton, Modal } from "../../components/Common";
import { Pagination, Search } from "../../components/Common/Table";
import { VscFilter } from "react-icons/vsc";
import {
  Box,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { MdMoreVert, MdPassword } from "react-icons/md";
import { HiOutlineTrash } from "react-icons/hi";
import { AiOutlineEdit } from "react-icons/ai";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import FilterAdmin from "./FilterAdmin";
import {
  AdminList,
  AdminCreate,
  AdminUpdate,
  AdminDelete,
  AdminStatusChange,
  ChnagePasswordOnMail,
} from "../../Service/Admin.service";
import { addAdminValidationSchema } from "../../helper/validation";
import {
  addAdminInitialValues,
  filterAdminInitialValues,
} from "../../helper/initialValues";
import { notificationSuccess } from "../../state/Action/NotificationAction";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { RoleDropdown } from "../../Service/Role.service";
import moment from "moment";
import TableRowsLoader from "../../components/Common/Loader/Skeleton";
import { IsDelete, IsWrite } from "../../helper/handlePermission";

const Admin = () => {
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: "",
    head: "",
  });
  const editPermissions = IsWrite("Admin");
  const deletePermissions = IsDelete("Admin");
  const [search, setSearch] = useState("");
  const [page, setPage] = React.useState(0);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [admindata, setAdminData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [roledata, setRoleData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: addAdminInitialValues,
    onSubmit: async (value) => {
      const payload = {
        firstName: value.firstName,
        lastName: value.lastName,
        email: value.email,
        role: value.role,
      };

      let res = isModalOpen?.isEdit
        ? await AdminUpdate(isModalOpen?.id, payload)
        : await AdminCreate(payload);
      if (res?.status) {
        dispatch(
          notificationSuccess({
            show: true,
            type: "success",
            message: res.message || "Success",
          })
        );
        getAdminList();
        setIsModalOpen({
          open: false,
          currentComponent: "",
          para: "",
          head: "",
          isEdit: false,
          isView: false,
        });
        formik.resetForm();
      } else {
        dispatch(
          notificationSuccess({
            show: true,
            type: "error",
            message:
              res.message || "Something went wrong. Please try again later.",
          })
        );
      }
    },
    validationSchema: addAdminValidationSchema,
  });

  console.log(
    "Math.ceil(totalData / rowsPerPage)",
    Math.ceil(totalData / rowsPerPage)
  );
  const formik1 = useFormik({
    initialValues: filterAdminInitialValues,
    onSubmit: async (value) => {
      setOpenDrawer(false);
      getAdminList();
    },
  });

  const getAdminList = async () => {
    setAdminData([]);
    let selectQry = `?page=${
      page + 1
    }&limit=${rowsPerPage}&search=${search}&status=${
      formik1.values.status
    }&role=${formik1.values.role}&sortBy=${sortConfig.key}&sortFlag=${
      sortConfig.direction
    }`;
    let res = await AdminList(selectQry);
    if (res?.status) {
      setAdminData(res.data);
      setTotalData(res.total);
    } else {
      setAdminData(null);
    }
  };

  useEffect(() => {
    getAdminList();
  }, [search, page, rowsPerPage, sortConfig]);

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };
  const changeStatus = async () => {
    let res =
      isModalOpen && isModalOpen?.currentComponent == "status"
        ? await AdminStatusChange(isModalOpen?.id)
        : isModalOpen && isModalOpen?.currentComponent == "delete"
        ? await AdminDelete(isModalOpen?.id)
        : isModalOpen && isModalOpen?.currentComponent == "changepassword"
        ? await ChnagePasswordOnMail(isModalOpen?.id)
        : null;
    if (res?.status) {
      dispatch(
        notificationSuccess({
          show: true,
          type: "success",
          message: res.message || "Success",
        })
      );
      getAdminList();
      setIsModalOpen({
        open: false,
        currentComponent: "",
        para: "",
        head: "",
        id: "",
      });
    } else {
      dispatch(
        notificationSuccess({
          show: true,
          type: "error",
          message:
            res.message || "Something went wrong. Please try again later.",
        })
      );
    }
  };

  const getDropdownData = async () => {
    let res = await RoleDropdown();
    if (res?.status) {
      setRoleData(res.data);
    } else {
      setRoleData([]);
    }
  };

  useEffect(() => {
    getDropdownData();
  }, []);

  return (
    <>
      <Heading head={"Admin "} />
      <Stack gap={2} mt={1}>
        <Stack
          mb={1}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Search search={search} setSearch={setSearch} setPage={setPage} />
          <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
            {editPermissions && (
              <Button
                buttonName={"Add"}
                size="small"
                onClick={() => {
                  setIsModalOpen({
                    open: true,
                    currentComponent: "addadmin",
                  });
                }}
              />
            )}
            <Button
              buttonName={<VscFilter />}
              style={{ padding: "10px 11px" }}
              color="white"
              size="small"
              onClick={toggleDrawer}
              variant="contained"
              sx={{ mt: 2 }}
            />
            <FilterAdmin
              openDrawer={openDrawer}
              toggleDrawer={toggleDrawer}
              formik={formik1}
              roledata={roledata}
            />
          </Stack>
        </Stack>
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  onClick={() =>
                    setSortConfig({
                      key: "firstName",
                      direction:
                        sortConfig &&
                        sortConfig.key === "firstName" &&
                        sortConfig.direction === "asc"
                          ? "desc"
                          : "asc",
                    })
                  }
                  style={{ cursor: "pointer" }}
                >
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"start"}
                  >
                    <div>Name</div>
                    {sortConfig &&
                    sortConfig.key === "firstName" &&
                    sortConfig.direction === "desc" ? (
                      <IoIosArrowUp
                        sx={{
                          color: "rgba(107, 114, 128, .5)",
                          fontSize: "17px",
                          marginLeft: "3px",
                        }}
                      />
                    ) : (
                      <IoIosArrowDown
                        sx={{
                          color: "rgba(107, 114, 128, .5)",
                          fontSize: "17px",
                          marginLeft: "3px",
                        }}
                      />
                    )}
                  </Stack>
                </TableCell>
                <TableCell style={{ cursor: "pointer" }}>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"start"}
                  >
                    <div>{"Email"}</div>
                  </Stack>
                </TableCell>
                <TableCell style={{ cursor: "pointer" }}>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"start"}
                  >
                    <div>{"Role"}</div>
                  </Stack>
                </TableCell>
                <TableCell
                  onClick={() =>
                    setSortConfig({
                      key: "createdAt",
                      direction:
                        sortConfig &&
                        sortConfig.key === "createdAt" &&
                        sortConfig.direction === "asc"
                          ? "desc"
                          : "asc",
                    })
                  }
                >
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"start"}
                  >
                    <div>Join Date</div>
                    {sortConfig &&
                    sortConfig.key === "createdAt" &&
                    sortConfig.direction === "desc" ? (
                      <IoIosArrowUp
                        sx={{
                          color: "rgba(107, 114, 128, .5)",
                          fontSize: "17px",
                          marginLeft: "3px",
                        }}
                      />
                    ) : (
                      <IoIosArrowDown
                        sx={{
                          color: "rgba(107, 114, 128, .5)",
                          fontSize: "17px",
                          marginLeft: "3px",
                        }}
                      />
                    )}
                  </Stack>
                </TableCell>
                <TableCell style={{ cursor: "pointer" }}>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"start"}
                  >
                    <div>{"Status"}</div>
                  </Stack>
                </TableCell>
                <TableCell style={{ cursor: "pointer", textAlign: "left" }}>
                  <Stack
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"end"}
                  >
                    <div>{"Action"}</div>
                  </Stack>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {admindata &&
                admindata.map((data) => (
                  <TableRow
                    key={data?._id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                    //   onClick={() => navigate("/auditor-details")}
                  >
                    <TableCell style={{ textAlign: "left" }}>
                      {data?.firstName} {data?.lastName}
                    </TableCell>
                    <TableCell style={{ textAlign: "left" }}>
                      {data?.email}
                    </TableCell>
                    <TableCell style={{ textAlign: "left" }}>
                      {data?.role?.roleName}
                    </TableCell>
                    <TableCell style={{ textAlign: "left" }}>
                      {moment(data?.createdAt).format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell style={{ textAlign: "left" }}>
                      <Switch
                        size="small"
                        checked={data?.status === 1 ? true : false}
                        disabled={formik1.values.status == 2 ? true : false}
                        onClick={(e) => {
                          e.stopPropagation();

                          if (editPermissions) {
                            setIsModalOpen({
                              id: data?._id,
                              open: true,
                              currentComponent: "status",
                              head: "Change Status",
                              para: "Are you sure you want to Change the Status?",
                            });
                          } else {
                            dispatch(
                              notificationSuccess({
                                show: true,
                                type: "error",
                                message:
                                  "You don't have permission to change status",
                              })
                            );
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell
                      onClick={(e) => e.stopPropagation()}
                      sx={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      {editPermissions || deletePermissions ? (
                        <DropdownMenu.Root>
                          <DropdownMenu.Trigger className="chatDropDown">
                            <MenuButton
                              icon={
                                <MdMoreVert
                                  style={{ color: "#000", fontSize: "18px" }}
                                />
                              }
                              width="30px"
                              height="33px"
                            />
                          </DropdownMenu.Trigger>
                          <DropdownMenu.Content
                            className="chatDropDownContent"
                            align="end"
                          >
                            {editPermissions && (
                              <>
                                <DropdownMenu.Item className="chatDropDownMenu">
                                  <Stack
                                    flexDirection={"row"}
                                    gap={1}
                                    alignItems={"center"}
                                    onClick={() => {
                                      formik.setFieldValue(
                                        "firstName",
                                        data?.firstName
                                      );
                                      formik.setFieldValue(
                                        "lastName",
                                        data?.lastName
                                      );
                                      formik.setFieldValue(
                                        "email",
                                        data?.email
                                      );
                                      formik.setFieldValue(
                                        "role",
                                        data?.role?._id
                                      );
                                      setIsModalOpen({
                                        id: data._id,
                                        open: true,
                                        currentComponent: "addadmin",
                                        isEdit: true,
                                      });
                                    }}
                                  >
                                    <AiOutlineEdit
                                      style={{ fontSize: "15px" }}
                                    />
                                    <Box sx={{ fontSize: "13px" }}>
                                      {"Edit"}
                                    </Box>
                                  </Stack>
                                </DropdownMenu.Item>
                                <DropdownMenu.Item className="chatDropDownMenu">
                                  <Stack
                                    flexDirection={"row"}
                                    gap={1}
                                    alignItems={"center"}
                                    onClick={() => {
                                      setIsModalOpen({
                                        id: data?._id,
                                        open: true,
                                        currentComponent: "changepassword",
                                        head: "Password Change",
                                        para: "Are You Sure to Change Password ",
                                      });
                                    }}
                                  >
                                    <MdPassword style={{ fontSize: "15px" }} />
                                    <Box sx={{ fontSize: "13px" }}>
                                      {"Change Password"}
                                    </Box>
                                  </Stack>
                                </DropdownMenu.Item>
                              </>
                            )}

                            {deletePermissions && (
                              <DropdownMenu.Item className="chatDropDownMenu">
                                <Stack
                                  flexDirection={"row"}
                                  gap={1}
                                  alignItems={"center"}
                                  onClick={() =>
                                    setIsModalOpen({
                                      open: true,
                                      currentComponent: "delete",
                                      head: "Delete Admin",
                                      para: "Are you sure you want to Delete this Admin?",
                                      id: data?._id,
                                    })
                                  }
                                >
                                  <HiOutlineTrash
                                    style={{ fontSize: "15px" }}
                                  />
                                  <Box sx={{ fontSize: "13px" }}>
                                    {"Delete"}
                                  </Box>
                                </Stack>
                              </DropdownMenu.Item>
                            )}
                          </DropdownMenu.Content>
                        </DropdownMenu.Root>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              {admindata == null ? (
                <TableRow>
                  <TableCell
                    sx={{
                      color: "silver",
                      textAlign: "center",
                      paddingTop: "90px",
                      borderBottom: "none",
                      fontSize: "30px",
                    }}
                    colSpan="6"
                  >
                    No records to display
                  </TableCell>
                </TableRow>
              ) : admindata && admindata.length == 0 ? (
                <TableRowsLoader colNumber={6} rowsNum={10} />
              ) : (
                ""
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {admindata != null && (
            <Pagination
              count={Math.ceil(totalData / rowsPerPage)}
              shape="rounded"
              totalData={totalData}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
            />
          )}
        </div>
      </Stack>
      <Modal
        modalOpen={isModalOpen}
        formik={formik}
        handleClose={() => {
          setIsModalOpen({ open: false, currentComponent: "", id: "" });
          formik.resetForm();
        }}
        data={roledata}
        handelClick={changeStatus}
      />
    </>
  );
};

export default Admin;
