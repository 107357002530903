import React, { useEffect, useRef, useState } from "react";
import pako from "pako";
import { Button, Heading, Input, Modal } from "../../../components/Common";
import {
  Autocomplete,
  Box,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { HiOutlineTrash } from "react-icons/hi";
import { v4 as uuidv4 } from "uuid";
import Papa from "papaparse";
import PropTypes from "prop-types";
import SkipLogic from "../SkipLogic";

///
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
////

const MultiSelectForm = ({
  isSelected,
  getPayload,
  index,
  formIndex,
  forms,
  setForms,
  logicQuestionSelecteData,
  setLogicQuestionSelecteData,
  setDisplayLogicQuestionSelecteData,
  displaylogicQuestionSelecteData,
  skipLogicData,
  skipLogicCondition,
  setSkipLogicCondition,
  setDisplayLogicCondition,
  displayLogicCondition,
}) => {
  const [minAnswer, setMinAnswer] = useState("");
  const [maxAnswer, setMaxAnswer] = useState("");
  const fileInputRef = useRef(null);
  const fileInput = useRef(null);
  const [type, setType] = useState("");
  const [isModalOpen, setIsModalOpen] = useState({
    id: "",
    open: false,
    currentComponent: "",
    head: "",
    para: "",
  });

  const handleMinAnswerChange = (event) => {
    setMinAnswer(event.target.value);
  };

  const convertFilteTobase64 = async (file) => {
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        let newArray = [...forms];
        // newArray[formIndex].file = reader.result;
        // setForms(newArray);
        // newArray[formIndex].data =
        newArray &&
          newArray[formIndex] &&
          newArray[formIndex].data &&
          newArray[formIndex].data.map((ans) =>
            ans.ids == fileInput.current.ids
              ? {
                  ...ans,
                  files: reader.result,
                }
              : ans
          );
        setForms(newArray);
      };
    }
  };

  const handleMaxAnswerChange = (event) => {
    setMaxAnswer(event.target.value);
  };

  const handleAddAnswer = () => {
    let newArray = [...forms];
    newArray[formIndex].data = [
      ...newArray[formIndex].data,
      { ids: uuidv4(), value: "" },
    ];
    setForms(newArray);
  };

  const handleDeleteAnswer = (id) => {
    let newArray = [...forms];
    newArray[formIndex].data = newArray[formIndex].data.filter(
      (answer) => answer.ids !== id
    );
    if (newArray[formIndex].data.length === 0) {
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      newArray[formIndex].file = "";
    }
    setForms(newArray);
  };

  const handleAnswer = (id, value) => {
    let newArray = [...forms];
    newArray[formIndex].data = newArray[formIndex].data.map((answer) =>
      answer.ids === id ? { ...answer, value } : answer
    );
    setForms(newArray);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    Papa.parse(file, {
      complete: (results) => {
        let parsedData = results.data.slice(1).map((row) => ({
          ids: uuidv4(),
          value: row[0].trim(),
          file: true,
          files: "",
        }));

        parsedData = parsedData.filter((item) => item.value != ""); // Remove entries with blank values
        let newArray = [...forms];
        if (
          newArray[formIndex].data &&
          newArray[formIndex].data[0] &&
          newArray[formIndex]?.data[0]?.value &&
          newArray[formIndex]?.data[0]?.value == ""
        ) {
          newArray[formIndex].data = parsedData;
        } else {
          // newArray[formIndex].data = [
          //   ...newArray[formIndex].data,
          //   ...parsedData,
          // ];
          newArray[formIndex].data = parsedData;
        }
        newArray[formIndex].file = event.target.files[0].name;
        setForms(newArray);
      },
      header: false,
    });
  };
  console.log("forms12121212121212121", forms[formIndex]);
  const handleFileRemove = () => {
    let newArray = [...forms];
    newArray[formIndex].file = "";
    newArray[formIndex].data = newArray[formIndex].data.filter(
      (answer) => !answer.file
    );
    newArray[formIndex].defaultAnswer = [];
    setForms(newArray);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleChange = (event) => {
    let newArray = [...forms];
    let ids = uuidv4();
    newArray[formIndex] = {
      ...getPayload(event.target.value),
      type: event.target.value,
      index: index,
      ids: ids,
      logicQuestionType:
        event.target.value == "text" ? "textEmail" : event.target.value,
    };
    setForms(newArray);
    let newArray1 = [...logicQuestionSelecteData];
    newArray1[index] = {
      ...getPayload(event.target.value),
      type: event.target.value,
      index: index,
      ids: ids,
      name: forms[index].question,
      value: "",
    };
    setLogicQuestionSelecteData(newArray1);

    let newArray2 = [...displaylogicQuestionSelecteData];
    newArray2[index] = {
      type: event.target.value,
      index: index,
      ids: ids,
      name: forms[index].question,
      value: "",
    };

    setDisplayLogicQuestionSelecteData(newArray2);
  };

  // useEffect(() => {
  //   fileInputRef.current.value = forms[formIndex].file;
  // }, [forms[formIndex].file]);

  //////////
  const [value, setValue] = React.useState(0);

  const handleChange1 = (event, newValue) => {
    setValue(newValue);
  };
  const [anchorElAdd, setAnchorElAdd] = React.useState(null);
  const [anchorElRemove, setAnchorElRemove] = React.useState(null);

  const openAdd = Boolean(anchorElAdd);
  const openRemove = Boolean(anchorElRemove);
  const idAdd = openAdd ? "add-popover" : undefined;
  const idRemove = openRemove ? "remove-popover" : undefined;

  const handleCloseRemove = () => {
    setAnchorElRemove(null);
  };

  return (
    <>
      <Stack
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "10px",
          flexDirection: "row",
          width: "100%",
          marginBottom: "20px",
        }}
      >
        <Stack sx={{ width: "100%" }}>
          <Stack
            sx={{
              backgroundColor: "white.main",
              borderRadius: "7px",
              padding: "20px",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              height: "fit-content",
              gap: "10px",
            }}
          >
            <Stack sx={{ display: "flex", flexDirection: "row", gap: "20px" }}>
              <Typography
                fontSize={{ xs: "10px", sm: "16px" }}
                fontWeight={500}
                mb={"2px"}
                width={"10px"}
                display={"flex"}
                flexDirection={"column"}
              >
                {formIndex + 1}.
              </Typography>
              <Stack
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <textarea
                  placeholder="Please Enter your Question text here..."
                  className="defaultinput textareaformcreation"
                  onChange={(e) => {
                    let newArray = [...forms];
                    newArray[formIndex].question = e.target.value;
                    setForms(newArray);

                    let newArray1 = [...logicQuestionSelecteData];
                    newArray1[index].name = e.target.value;
                    setLogicQuestionSelecteData(newArray1);

                    let newArray2 = [...displaylogicQuestionSelecteData];
                    newArray2[index].name = e.target.value;
                    setDisplayLogicQuestionSelecteData(newArray2);
                  }}
                  value={forms[formIndex].question}
                />
                <Stack width={"100%"}>
                  <Input
                    placeholder="Instruction Text"
                    onChange={(e) => {
                      let newArray = [...forms];
                      newArray[formIndex].intructinText = e.target.value;
                      setForms(newArray);
                    }}
                    value={forms[formIndex].intructinText}
                    style={{ width: "100%" }}
                  />
                </Stack>
              </Stack>
            </Stack>
            {/* header */}
            <Stack
              sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
              className="formcard"
            >
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                  paddingTop: "10px !important",
                }}
              >
                <Box className="headerrow">
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "20px",
                    }}
                  >
                    <FormControl>
                      <Select
                        value={forms[formIndex].type}
                        onChange={handleChange}
                        displayEmpty
                        sx={{
                          width: 300,
                          ".MuiSelect-select": {
                            padding: "10px",
                          },
                        }}
                      >
                        <MenuItem
                          value=""
                          sx={{ display: "flex", gap: "10px" }}
                        >
                          Select Question Type
                        </MenuItem>
                        <MenuItem
                          value="text"
                          sx={{ display: "flex", gap: "10px" }}
                        >
                          Text
                        </MenuItem>
                        <MenuItem
                          value="multiSelect"
                          sx={{ display: "flex", gap: "10px" }}
                        >
                          {" "}
                          Multiple Choice
                        </MenuItem>
                        <MenuItem
                          value="scale"
                          sx={{ display: "flex", gap: "10px" }}
                        >
                          {" "}
                          Scale
                        </MenuItem>
                        <MenuItem
                          value="DropDown"
                          sx={{ display: "flex", gap: "10px" }}
                        >
                          {" "}
                          Drop Down
                        </MenuItem>
                        <MenuItem
                          value="Metrix"
                          sx={{ display: "flex", gap: "10px" }}
                        >
                          {" "}
                          Metrix
                        </MenuItem>
                        <MenuItem
                          value="Scan"
                          sx={{ display: "flex", gap: "10px" }}
                        >
                          Media Capture
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <Box sx={{ width: "fit-content" }}>
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                          value={value}
                          onChange={handleChange1}
                          aria-label="basic tabs example"
                        >
                          <Tab label="Options" {...a11yProps(0)} />
                          <Tab label="Logic" {...a11yProps(1)} />
                        </Tabs>
                      </Box>
                    </Box>
                  </Stack>
                  <label style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="checkbox"
                      style={{
                        marginRight: "10px",
                        height: "17px",
                        width: "17px",
                      }}
                      onChange={(e) => {
                        let newArray = [...forms];
                        newArray[formIndex].answerRequired = e.target.checked;
                        setForms(newArray);
                      }}
                      checked={forms[formIndex].answerRequired}
                    />
                    Answer Required
                  </label>
                </Box>
              </Stack>
              <div className="verticalline"></div>
              {/* body */}
              <CustomTabPanel value={value} index={0}>
                <Stack spacing={2}>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "start",
                      width: "100%",
                    }}
                  >
                    <Stack
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "30px",
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                          justifyContent: "flex-start",
                          width: "100%",
                        }}
                      >
                        <Typography
                          fontSize={{ xs: "10px", sm: "16px" }}
                          fontWeight={500}
                          mb={"2px"}
                          width={"150px"}
                        >
                          Type:
                        </Typography>
                        <FormControl>
                          <Select
                            sx={{ width: "250px", display: "flex" }}
                            labelId="demo-simple  -select-label"
                            id="demo-simple-select"
                            displayEmpty
                            onChange={(e) => {
                              let newArray = [...forms];
                              newArray[formIndex].answerType = e.target.value;
                              setForms(newArray);
                              if (e.target.value === "single") {
                                let newArray = [...forms];
                                newArray[formIndex].selectionLimit = "";
                                newArray[formIndex].minRange = "";
                                newArray[formIndex].maxRange = "";
                                newArray[formIndex].exactNumber = "";
                                newArray[formIndex].defaultAnswer = [];
                                setForms(newArray);
                              }
                            }}
                            value={forms[formIndex].answerType}
                          >
                            <MenuItem value="multiple">Multiple</MenuItem>
                            <MenuItem value="single">Single</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                          width: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            width: "100%",
                            gap: "10px",
                          }}
                        >
                          <label
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <input
                              type="checkbox"
                              style={{
                                marginRight: "10px",
                                height: "17px",
                                width: "17px",
                              }}
                              onChange={(e) => {
                                let newArray = [...forms];
                                newArray[formIndex].randomize =
                                  e.target.checked;
                                setForms(newArray);
                              }}
                              checked={forms[formIndex].randomize}
                            />
                            Randomize
                          </label>
                          <label
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <input
                              type="checkbox"
                              style={{
                                marginRight: "10px",
                                height: "17px",
                                width: "17px",
                              }}
                              onChange={(e) => {
                                let newArray = [...forms];
                                newArray[formIndex].otherOption =
                                  e.target.checked;
                                setForms(newArray);
                              }}
                              checked={forms[formIndex].otherOption}
                            />
                            Allow Other Option
                          </label>
                        </Box>
                      </Box>
                    </Stack>
                  </Box>
                  <Stack
                    sx={{ display: "flex", flexDirection: "row", gap: "10px" }}
                  >
                    {forms[formIndex].answerType === "multiple" && (
                      <Box
                        sx={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          fontSize={{ xs: "10px", sm: "16px" }}
                          fontWeight={500}
                          mb={"2px"}
                          width={"150px"}
                        >
                          Selection Limits
                        </Typography>
                        <FormControl sx={{ width: "250px", display: "flex" }}>
                          <Select
                            labelId="demo-simple  -select-label"
                            id="demo-simple-select"
                            displayEmpty
                            onChange={(e) => {
                              let newArray = [...forms];
                              newArray[formIndex].selectionLimit =
                                e.target.value;
                              newArray[formIndex].minRange = 0;
                              newArray[formIndex].maxRange = 0;
                              newArray[formIndex].exactNumber = "";
                              setForms(newArray);
                            }}
                            value={forms[formIndex].selectionLimit}
                          >
                            <MenuItem value="unlimited">Unlimited</MenuItem>
                            <MenuItem value="range">Range</MenuItem>
                            <MenuItem value="exactNumber">
                              Exact Number
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    )}
                    {forms[formIndex].selectionLimit === "range" && (
                      <>
                        <Input
                          placeholder="Min"
                          type="number"
                          value={forms[formIndex].minRange}
                          onChange={(e) => {
                            let newArray = [...forms];
                            newArray[formIndex].minRange = e.target.value;
                            setForms(newArray);
                          }}
                          // label="Min"
                        />
                        <Input
                          placeholder="Max"
                          type="number"
                          value={forms[formIndex].maxRange}
                          onChange={(e) => {
                            let newArray = [...forms];
                            newArray[formIndex].maxRange = e.target.value;
                            setForms(newArray);
                          }}
                          // label="Max"
                        />
                      </>
                    )}
                    {forms[formIndex].selectionLimit === "exactNumber" && (
                      // <select
                      //   value={forms[formIndex].exactNumber}
                      //   onChange={(e) => {
                      //     let newArray = [...forms];
                      //     newArray[formIndex].exactNumber = e.target.value;
                      //     setForms(newArray);
                      //   }}
                      //   style={{
                      //     width: "250px",
                      //     padding: "10px",
                      //     border: "1px solid #ccc",
                      //     borderRadius: "5px"
                      //   }}
                      // >
                      //   <option value="">Select Exact Number</option>
                      //   {forms[formIndex].data.map((answer, index) => (
                      //     <option key={answer.ids} value={index + 1}>
                      //       {index + 1}
                      //     </option>
                      //   ))}
                      // </select>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <Typography
                          fontSize={{ xs: "10px", sm: "16px" }}
                          fontWeight={500}
                          mb={"2px"}
                        >
                          Exact Number
                        </Typography>
                        <FormControl sx={{ width: "250px", display: "flex" }}>
                          <Select
                            labelId="demo-simple  -select-label"
                            id="demo-simple-select"
                            displayEmpty
                            onChange={(e) => {
                              let newArray = [...forms];
                              newArray[formIndex].exactNumber = e.target.value;
                              setForms(newArray);
                            }}
                            value={forms[formIndex].exactNumber}
                          >
                            <MenuItem value="">Select Exact Number</MenuItem>
                            {forms[formIndex].data.map((answer, index) => (
                              <MenuItem key={answer.ids} value={index + 1}>
                                {index + 1}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                    )}
                  </Stack>
                  <Divider />
                  <Stack
                    sx={{ display: "flex", flexDirection: "row", gap: "20px" }}
                  >
                    <Typography
                      fontSize={{ xs: "10px", sm: "16px" }}
                      fontWeight={500}
                      mb={"2px"}
                      width={"140px"}
                      display={"flex"}
                      flexDirection={"column"}
                    >
                      Default Answer:
                    </Typography>

                    {forms[formIndex].answerType === "single" ? (
                      <FormControl sx={{ width: "250px", display: "flex" }}>
                        <Select
                          labelId="demo-simple  -select-label"
                          id="demo-simple-select"
                          displayEmpty
                          onChange={(e) => {
                            let newArray = [...forms];
                            newArray[formIndex].defaultAnswer = [
                              e.target.value,
                            ];
                            setForms(newArray);
                          }}
                          value={forms[formIndex].defaultAnswer[0]}
                        >
                          <MenuItem value="">Select Default Answer</MenuItem>
                          {forms[formIndex].data.map((answer, index) => (
                            <MenuItem key={answer.ids} value={answer.ids}>
                              {answer.value}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : (
                      <Autocomplete
                        multiple
                        id="tags-outlined"
                        options={forms[formIndex].data.filter(
                          (ans) =>
                            ans.value != "" &&
                            !forms[formIndex].defaultAnswer.includes(ans.ids)
                        )}
                        getOptionLabel={(option) =>
                          option.value != "" ? option.value : ""
                        }
                        filterSelectedOptions
                        onChange={(e, value) => {
                          let newArray = [...forms];
                          newArray[formIndex].defaultAnswer = value.map(
                            (val) => val.ids
                          );
                          setForms(newArray);
                        }}
                        value={forms[formIndex].defaultAnswer.map((ans) => {
                          return forms[formIndex].data.find(
                            (data) => data.ids === ans
                          );
                        })}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            style={{ width: "350px" }}
                            placeholder="Default Answer"
                          />
                        )}
                      />
                    )}
                  </Stack>
                  <Divider />
                  {!forms[formIndex].pitcherChoice && (
                    <Stack
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "20px",
                      }}
                    >
                      <Typography
                        fontSize={{ xs: "10px", sm: "16px" }}
                        fontWeight={500}
                        display={"flex"}
                        flexDirection={"column"}
                        width={"140px"}
                      >
                        Upload File:
                      </Typography>
                      {forms[formIndex].file === "" && (
                        <Button
                          buttonName={"Choose File"}
                          onClick={() => fileInputRef.current.click()}
                        />
                      )}
                      <input
                        type="file"
                        onChange={handleFileChange}
                        accept=".csv"
                        // value={forms[formIndex].file}
                        ref={fileInputRef}
                        style={{ display: "none" }}
                      />
                      {forms[formIndex].file && (
                        <Typography
                          fontSize={{ xs: "10px", sm: "16px" }}
                          fontWeight={500}
                          display={"flex"}
                          flexDirection={"column"}
                          width={
                            forms[formIndex].file.length > 20
                              ? `${forms[formIndex].file.length * 10}px`
                              : "100%"
                          }
                        >
                          {forms[formIndex].file}
                        </Typography>
                      )}
                      {forms[formIndex].file && (
                        <Button
                          buttonName={"Remove File"}
                          onClick={handleFileRemove}
                          style={{ backgroundColor: "red", color: "white" }}
                        />
                      )}
                    </Stack>
                  )}
                  <Divider />
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "20px",
                    }}
                  >
                    <label style={{ display: "flex", alignItems: "center" }}>
                      <input
                        type="checkbox"
                        style={{
                          marginRight: "10px",
                          height: "17px",
                          width: "17px",
                        }}
                        onChange={(e) => {
                          let newArray = [...forms];
                          newArray[formIndex].pitcherChoice = e.target.checked;
                          newArray[formIndex].data = [];
                          setForms(newArray);
                          if (fileInputRef.current) {
                            fileInputRef.current.value = "";
                          }
                        }}
                        checked={forms[formIndex].pitcherChoice}
                        defaultChecked={forms[formIndex].pitcherChoice}
                      />
                      Picture choices
                    </label>
                  </Stack>
                  <Divider />
                  <Stack
                    sx={{ display: "flex", flexDirection: "row", gap: "20px" }}
                  >
                    <Typography
                      fontSize={{ xs: "10px", sm: "16px" }}
                      fontWeight={500}
                      mb={"2px"}
                      width={"100px"}
                      display={"flex"}
                      flexDirection={"column"}
                    >
                      Answers:
                    </Typography>
                    {!forms[formIndex].file ? (
                      <Box sx={{ width: "100%" }}>
                        {forms &&
                          forms[formIndex] &&
                          forms[formIndex].data &&
                          forms[formIndex].data.map((answer, index) => (
                            <Box
                              key={answer.id}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "10px",
                                gap: "10px",
                              }}
                            >
                              <Box
                                sx={{
                                  flex: 1,
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "10px",
                                }}
                              >
                                <Input
                                  placeholder={`Ans.${index + 1}`}
                                  value={answer.value}
                                  onChange={(e) =>
                                    handleAnswer(answer.ids, e.target.value)
                                  }
                                  sx={{ width: "100%" }}
                                />
                                {forms[formIndex].pitcherChoice && (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      gap: "10px",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Stack
                                      sx={{
                                        width: "200px",
                                        height: "150px",
                                        border: "1px dashed black",
                                      }}
                                    >
                                      {answer.files && answer.files != "" && (
                                        <img
                                          src={answer.files}
                                          alt="uploaded"
                                          style={{
                                            width: "100%",
                                            height: "150px",
                                            objectFit: "cover",
                                          }}
                                        />
                                      )}
                                    </Stack>
                                    <Button
                                      buttonName={"Upload Image"}
                                      color="white"
                                      style={{ height: "fit-content" }}
                                      onClick={() => {
                                        if (fileInput.current) {
                                          fileInput.current.click();
                                          //set ids in file input
                                          fileInput.current.ids = answer.ids;
                                        }
                                      }}
                                    />
                                    {answer.files && answer.files != "" && (
                                      <Button
                                        buttonName={"Remove Image"}
                                        onClick={() => {
                                          let newArray = [...forms];
                                          newArray[formIndex].data = newArray[
                                            formIndex
                                          ].data.map((ans) =>
                                            ans.ids === answer.ids
                                              ? {
                                                  ...ans,
                                                  files: "",
                                                }
                                              : ans
                                          );
                                          setForms(newArray);
                                        }}
                                      />
                                    )}
                                    <input
                                      type="file"
                                      ref={fileInput}
                                      style={{ display: "none" }}
                                      onChange={(e) => {
                                        if (e.target.files.length) {
                                          const file = e.target.files[0];
                                          // convertFilteTobase64(file);

                                          const reader = new FileReader();
                                          reader.readAsDataURL(file);

                                          reader.onloadend = () => {
                                            let newArray = [...forms];

                                            newArray[formIndex].data =
                                              newArray &&
                                              newArray[formIndex] &&
                                              newArray[formIndex].data &&
                                              newArray[formIndex].data.map(
                                                (ans) =>
                                                  ans.ids ==
                                                  fileInput.current.ids
                                                    ? {
                                                        ...ans,
                                                        files: reader.result,
                                                      }
                                                    : ans
                                              );
                                            setForms(newArray);
                                          };
                                        }
                                      }}
                                    />
                                  </Box>
                                )}
                              </Box>
                              <Button
                                onClick={() => handleDeleteAnswer(answer.ids)}
                                style={{
                                  backgroundColor: "red",
                                  color: "white",
                                }}
                                buttonName={
                                  <HiOutlineTrash
                                    style={{ fontSize: "15px" }}
                                  />
                                }
                              />
                            </Box>
                          ))}
                        <Box sx={{ marginTop: "10px" }}>
                          <Button
                            onClick={handleAddAnswer}
                            buttonName={"Add Answer"}
                          />
                        </Box>
                      </Box>
                    ) : (
                      <Box sx={{ width: "100%" }}>
                        {forms &&
                          forms[formIndex] &&
                          forms[formIndex].data &&
                          forms[formIndex].data && (
                            <Typography>
                              Total Rows: {forms[formIndex].data.length}
                            </Typography>
                          )}
                      </Box>
                    )}
                  </Stack>
                </Stack>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <Stack spacing={2}>
                  <SkipLogic
                    logicQuestionSelecteData={logicQuestionSelecteData}
                    forms={forms}
                    setForms={setForms}
                    index={index}
                    setLogicQuestionSelecteData={setLogicQuestionSelecteData}
                    skipLogicData={skipLogicData}
                    skipLogicCondition={skipLogicCondition}
                    setSkipLogicCondition={setSkipLogicCondition}
                    displaylogicQuestionSelecteData={
                      displaylogicQuestionSelecteData
                    }
                    displayLogicCondition={displayLogicCondition}
                    setDisplayLogicCondition={setDisplayLogicCondition}
                    setDisplayLogicQuestionSelecteData={
                      setDisplayLogicQuestionSelecteData
                    }
                  />
                </Stack>
              </CustomTabPanel>

              {/* <Stack
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "row",
            }}
          >
            <Button
              buttonName={"Insert New Question Here"}
              color="orange"
              onClick={onAddMultiSelectForm}
            />
          </Stack> */}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Modal
        modalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        handleClose={() =>
          setIsModalOpen({
            open: false,
            currentComponent: "",
            para: "",
            head: "",
          })
        }
      />
    </>
  );
};
export default MultiSelectForm;
