import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";

const DropdownView = ({ item, index }) => {
  console.log("itcxcxcxcxcxcem", item);
  return (
    <>
      <Stack>
        <Box>
          <Typography
            style={{ color: "gray" }}
            fontSize={{ sx: "12px", xs: "14px" }}
          >
            {" "}
            <span
              style={{
                color: "black",
                fontSize: "18px",
              }}
            >
              {index}.{" "}
            </span>{" "}
            {item?.question}
          </Typography>
        </Box>
        <Grid container spacing={2}>
          {item?.answers && item?.answers?.length > 0 ? (
            item?.answers.map((ans, index) => (
              <Grid item xs={4}>
                <Box
                  sx={{
                    padding: "5px",
                  }}
                >
                  &nbsp;&nbsp;&nbsp; {ans}
                </Box>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Typography
                style={{ color: "gray" }}
                fontSize={{ sx: "12px", xs: "14px" }}
              >
                {" "}
                &nbsp;&nbsp;&nbsp;&nbsp; No Answer Given
              </Typography>
            </Grid>
          )}
        </Grid>
      </Stack>
    </>
  );
};

export default DropdownView;
