import React, { useEffect, useMemo, useState } from "react";
import { Button, Heading, Input } from "../../components/Common";
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Popover,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import TextForm from "./TextForm/TextForm";
import MultiSelectForm from "./MultiSelect/MultiSelectForm";
import Scale from "./Scale/Scale";
import DropDownForm from "./DropDown/DropDownForm";
import { formAdd, formUpdate } from "../../Service/form.service";
import { useFormik } from "formik";
import { addFormInitialValues } from "../../helper/initialValues";
import { notificationSuccess } from "../../state/Action/NotificationAction";
import { useDispatch } from "react-redux";
import { addFormValidationSchema } from "../../helper/validation";
import { useNavigate } from "react-router-dom";
import MatrixMain from "./MetrixType/MatrixMain";
import { v4 as uuidv4 } from "uuid";
import { FaPlus, FaRegSquareCaretDown } from "react-icons/fa6";
import { BsThreeDotsVertical, BsTrash } from "react-icons/bs";
import { HiOutlineDuplicate } from "react-icons/hi";
import { useDrag, useDrop } from "react-dnd";
import Scan from "./Scan/Scan";
import { useLocation } from "react-router-dom";
import { formDetail } from "../../Service/form.service";
import moment from "moment";

const textFromPayload = {
  question: "",
  type: "text",
  intructinText: "",
  minCharactor: 0,
  maxCharactor: 1000,
  answerRequired: false,
  emailRequired: true,
  onlyNumber: false,
  dateRange: false,
  setDefaultDate: false,
  logicIndex: "",
  logicQuestionType: "",
  skipLogicValue: "",
  dlogicIndex: "",
  dlogicSelectIndex: "",
  dlogicQuestionType: "",
  displayLogicValue: "",
};

const multiSelectPayload = {
  question: "",
  type: "multiSelect",
  intructinText: "",
  answerType: "single",
  otherOption: false,
  defaultAnswer: [],
  selectionLimit: "unlimited",
  minRange: 0,
  maxRange: 0,
  pitcherChoice: false,
  file: "",
  exactNumber: 0,
  data: [{ ids: uuidv4(), value: "" }],
  answerRequired: false,
  randomize: false,
  includeScore: false,
  displayImage: false,
  logicIndex: "",
  logicQuestionType: "",
  skipLogicValue: "",
  dlogicIndex: "",
  dlogicSelectIndex: "",
  dlogicQuestionType: "",
  displayLogicValue: "",
};

const scalePayload = {
  question: "",
  type: "scale",
  intructinText: "",
  leftLabel: "",
  rightLabel: "",
  answerRequired: false,
  displaySliderValuePercentage: false,
  minRange: 0,
  maxRange: 100,
  logicIndex: "",
  logicQuestionType: "",
  skipLogicValue: "",
  dlogicIndex: "",
  dlogicSelectIndex: "",

  dlogicQuestionType: "",
  displayLogicValue: "",
};

const dropDownPayload = {
  question: "",
  type: "DropDown",
  answerType: "single",
  intructinText: "",
  data: [{ ids: uuidv4(), value: "" }],
  answerRequired: false,
  otherOption: false,
  defaultAnswer: [],
  file: "",
  fixRowSelectBoxType: "",
  selectionLimit: "unlimited",
  minRange: 0,
  maxRange: 0,
  exactNumber: 0,
  logicIndex: "",
  logicQuestionType: "",
  skipLogicValue: "",
  dlogicIndex: "",
  dlogicSelectIndex: "",

  dlogicQuestionType: "",
  displayLogicValue: "",
};

const matrix = {
  question: "",
  type: "Metrix",
  intructinText: "",
  autoIncrement: false,
  file: "",
  data: [],
  metrixData: [
    [
      { type: "text", value: "", dataArray: [], disable: true },
      { type: "text", value: "", dataArray: [], disable: true },
    ],
  ],
  answerRequired: false,
  randomize: false,
  rowType: "dynamic",
  dataArray: [],
  fixRowSelectBoxType: "text",
  logicIndex: "",
  logicQuestionType: "",
  skipLogicValue: "",
  dlogicIndex: "",
  dlogicSelectIndex: "",

  dlogicQuestionType: "",
  displayLogicValue: "",
};

const scan = {
  question: "",
  type: "Scan",
  intructinText: "",
  answerRequired: false,
  scanType: "singal",
  logicIndex: "",
  dlogicIndex: "",
  dlogicSelectIndex: "",

  dlogicQuestionType: "",
  displayLogicValue: "",

  logicQuestionType: "",
  skipLogicValue: "",
};

const skipLogicData = [
  {
    type: "textEmail",
    data: [
      { name: "Starts with", value: "Starts with" },
      { name: "Ends with", value: "Ends with" },
      { name: "Contains", value: "Contains" },
      { name: "Equal to", value: "Equal to" },
      { name: "Not equal to", value: "Not equal to" },
      { name: "isAnswered", value: "isAnswered" },
      { name: "isNotAnswered", value: "isNotAnswered" },
      { name: "Does not contain", value: "Does not contain" },
    ],
  },
  {
    type: "number",
    data: [
      { name: "Equal to", value: "Equal to" },
      { name: "Not equal to", value: "Not equal to" },
      { name: "Greater than", value: "Greater than" },
      { name: "Less than", value: "Less than" },
      { name: "isAnswered", value: "isAnswered" },
      { name: "isNotAnswered", value: "isNotAnswered" },
    ],
  },
  {
    type: "scale",
    data: [
      { name: "Equal to", value: "Equal to" },
      { name: "Not equal to", value: "Not equal to" },
      { name: "Greater than", value: "Greater than" },
      { name: "Less than", value: "Less than" },
      { name: "isAnswered", value: "isAnswered" },
      { name: "isNotAnswered", value: "isNotAnswered" },
    ],
  },
  {
    type: "date",
    data: [
      { name: "Equal to", value: "Equal to" },
      { name: "Not equal to", value: "Not equal to" },
      { name: "Greater than", value: "Greater than" },
      { name: "Less than", value: "Less than" },
      { name: "isAnswered", value: "isAnswered" },
      { name: "isNotAnswered", value: "isNotAnswered" },
    ],
  },
  {
    type: "time",
    data: [
      { name: "Equal to", value: "Equal to" },
      { name: "Not equal to", value: "Not equal to" },
      { name: "Greater than", value: "Greater than" },
      { name: "Less than", value: "Less than" },
      { name: "isAnswered", value: "isAnswered" },
      { name: "isNotAnswered", value: "isNotAnswered" },
    ],
  },
  {
    type: "DropDown",
    data: [
      { name: "Responded selected", value: "Responded selected" },
      { name: "Responded Not selected", value: "Responded Not selected" },
      { name: "isAnswered", value: "isAnswered" },
      { name: "isNotAnswered", value: "isNotAnswered" },
      { name: "Equal to", value: "Equal to" },
      { name: "Not equal to", value: "Not equal to" },
    ],
  },
  {
    type: "multiSelect",
    data: [
      { name: "Responded selected", value: "Responded selected" },
      { name: "Responded Not selected", value: "Responded Not selected" },
      { name: "Equal to", value: "Equal to" },
      { name: "Not equal to", value: "Not equal to" },
      { name: "isAnswered", value: "isAnswered" },
      { name: "isNotAnswered", value: "isNotAnswered" },
    ],
  },
  {
    type: "Scan",
    data: [
      { name: "isAnswered", value: "isAnswered" },
      { name: "isNotAnswered", value: "isNotAnswered" },
    ],
  },
  {
    type: "Metrix",
    data: [
      { name: "isAnswered", value: "isAnswered" },
      { name: "isNotAnswered", value: "isNotAnswered" },
      { name: "Responded selected", value: "Responded selected" },
      { name: "Responded Not selected", value: "Responded Not selected" },
    ],
  },
];

const FormCreation = () => {
  const [forms, setForms] = useState([]);
  const [logicQuestionSelecteData, setLogicQuestionSelecteData] = useState([]);
  const [displaylogicQuestionSelecteData, setDisplayLogicQuestionSelecteData] =
    useState([]);

  const [encryptedValue, setEncryptedValue] = useState("");
  const [editId, setEditId] = useState("");
  const [skipLogicCondition, setSkipLogicCondition] = useState([]);
  const [displayLogicCondition, setDisplayLogicCondition] = useState([]);
  const [selectedForm, setSelectedForm] = useState({
    type: "",
    index: null,
    path: "",
  }); // State to track selected form type
  const ItemTypes = {
    FORM: "form",
  };
  const { state } = useLocation();
  useEffect(() => {
    // Get the query string from the URL
    const queryString = window.location.search;

    // Create a URLSearchParams object
    const queryParams = new URLSearchParams(queryString);

    // Get the value of the parameter (assumes the value is the first parameter in the query string)
    const value = queryParams.toString(); // Get the full query string

    setEncryptedValue(value);
  }, []);

  console.log("skipLogicCondition", displayLogicCondition);

  useEffect(() => {
    if (state && state.id) {
      setEditId(state.id);
      getFormDetail(state?.id);
    }
  }, [state]);

  const dispatch = useDispatch();
  const Navigate = useNavigate();

  const [showButtons, setShowButtons] = useState({
    show: false,
    index: 1,
  });

  const formik = useFormik({
    initialValues: addFormInitialValues,
    onSubmit: async (value) => {
      if (forms.length === 0) {
        dispatch(
          notificationSuccess({
            show: true,
            type: "error",
            message: "Please add atleast one question",
          })
        );
        return;
      }

      const payload = {
        title: value.title,
        expireDate: value.expireDate,
        questions: JSON.stringify(forms),
        displayLogic: JSON.stringify(displaylogicQuestionSelecteData),
        skipLogic: JSON.stringify(logicQuestionSelecteData),
        skipLogicCondition: JSON.stringify(skipLogicCondition),
        displayLogicCondition: JSON.stringify(displayLogicCondition),
      };

      let res = editId
        ? await formUpdate(editId, payload)
        : await formAdd(payload);
      if (res?.status) {
        dispatch(
          notificationSuccess({
            show: true,
            type: "success",
            message: res.message || "Success",
          })
        );
        Navigate(`/form-management?${encryptedValue}`);
        formik.resetForm();
      } else {
        dispatch(
          notificationSuccess({
            show: true,
            type: "error",
            message: res.message || "Failed",
          })
        );
      }
    },
    validationSchema: addFormValidationSchema,
  });

  const getPayload = (type) => {
    switch (type) {
      case "text":
        return textFromPayload;
      case "multiSelect":
        return multiSelectPayload;
      case "scale":
        return scalePayload;
      case "DropDown":
        return {
          ...dropDownPayload,
          data: [{ ids: uuidv4(), value: "" }],
        };
      case "scaleMetrix":
        return matrix;
      case "Metrix":
        return matrix;
      case "Scan":
        return scan;
      default:
        return null;
    }
  };

  const handleAddForm = (type) => {
    let ids = uuidv4();
    setForms([
      ...forms,
      {
        ...getPayload(type),
        type: type,
        logicQuestionType: type == "text" ? "textEmail" : type,
        index: forms.length,
        ids: ids,
      },
    ]);
    // if (type == "Metrix") {
    //   setLogicQuestionSelecteData([
    //     ...logicQuestionSelecteData,
    //     {
    //       type: type,
    //       index: forms.length,
    //       subIndex: 0,
    //       ids: ids,
    //       name: "",
    //       value: "",
    //     },
    //     {
    //       type: type,
    //       index: forms.length,
    //       ids: ids,
    //       subIndex: 1,
    //       name: "",
    //       value: "",
    //     },
    //   ]);
    // } else {
    setLogicQuestionSelecteData([
      ...logicQuestionSelecteData,
      {
        type: type,
        index: forms.length,
        subIndex: 0,
        ids: ids,
        name: "",
        value: "",
      },
    ]);

    setDisplayLogicQuestionSelecteData([
      ...displaylogicQuestionSelecteData,
      {
        type: type,
        index: forms.length,
        subIndex: 0,
        ids: ids,
        name: "",
        value: "",
      },
    ]);

    // }

    setSkipLogicCondition([
      ...skipLogicCondition,
      {
        questionIndex: "",
        questionType: "",
        condition: "",
        jumpTo: "",
        value: "",
        ids: ids,
      },
    ]);
    setDisplayLogicCondition([
      ...displayLogicCondition,
      {
        questionIndex: "",
        questionType: "",
        condition: "",
        value: "",
        ids: ids,
      },
    ]);
  };

  const handleDeleteForm = (ids) => {
    setForms(forms.filter((form) => form.ids !== ids));
    setLogicQuestionSelecteData(
      logicQuestionSelecteData.filter((form) => form.ids !== ids)
    );
    setSkipLogicCondition(
      skipLogicCondition.filter((form) => form.ids !== ids)
    );
    setDisplayLogicCondition(
      displayLogicCondition.filter((form) => form.ids !== ids)
    );
    setDisplayLogicQuestionSelecteData(
      displaylogicQuestionSelecteData.filter((form) => form.ids !== ids)
    );
  };

  const handleClose = () =>
    setShowButtons({
      show: false,
      index: 1,
    });

  const renderFormComponent = (form, index) => {
    const commonProps = {
      key: form.ids,
      onDelete: () => handleDeleteForm(form.ids),
      isSelected: selectedForm,
      index: index,
      formIndex: index,
      forms: forms,
      setForms: setForms,
      getPayload: getPayload,
      skipLogicData: skipLogicData,
      setLogicQuestionSelecteData: setLogicQuestionSelecteData,
      setDisplayLogicQuestionSelecteData: setDisplayLogicQuestionSelecteData,
      displaylogicQuestionSelecteData: displaylogicQuestionSelecteData,
      logicQuestionSelecteData: logicQuestionSelecteData,
      setSkipLogicCondition: setSkipLogicCondition,
      skipLogicCondition: skipLogicCondition,
      setDisplayLogicCondition: setDisplayLogicCondition,
      displayLogicCondition: displayLogicCondition,
    };

    switch (form.type) {
      case "text":
        return (
          <TextForm
            {...commonProps}
            onAddTextForm={() => handleAddForm("text")}
          />
        );
      case "multiSelect":
        return <MultiSelectForm {...commonProps} />;
      case "scale":
        return <Scale {...commonProps} />;
      case "DropDown":
        return <DropDownForm {...commonProps} />;
      case "Metrix":
        return <MatrixMain {...commonProps} />;
      case "Scan":
        return <Scan {...commonProps} />;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (forms.length === 0) {
      setSelectedForm({ type: "", index: null, path: "" });
    }
  }, [forms]);

  useEffect(() => {
    localStorage.setItem(
      "selectedFromData",
      JSON.stringify(forms[selectedForm.index])
    );
  }, [forms, selectedForm.index]);

  const formChange = useMemo(() => {
    return (
      <Stack style={{ width: "100%" }}>
        {forms.map((form, index) => {
          if (selectedForm.index === index) {
            return renderFormComponent(form, selectedForm.index);
          }
        })}
      </Stack>
    );
  }, [forms, selectedForm]);

  const DraggableForm = ({
    form,
    index,
    selectedForm,
    setSelectedForm,
    setShowButtons,
    forms,
    setForms,
  }) => {
    const [, drag] = useDrag({
      type: ItemTypes.FORM,
      item: { index },
    });

    const [, drop] = useDrop({
      accept: ItemTypes.FORM,
      hover(item) {
        if (item.index !== index) {
          const newForms = [...forms];
          [newForms[item.index], newForms[index]] = [
            newForms[index],
            newForms[item.index],
          ];
          setForms(newForms);
          const newLogicQuestionSelecteData = [...logicQuestionSelecteData];
          [
            newLogicQuestionSelecteData[item.index],
            newLogicQuestionSelecteData[index],
          ] = [
            newLogicQuestionSelecteData[index],
            newLogicQuestionSelecteData[item.index],
          ];
          setLogicQuestionSelecteData(newLogicQuestionSelecteData);
          const newSkipLogicCondition = [...skipLogicCondition];
          [newSkipLogicCondition[item.index], newSkipLogicCondition[index]] = [
            newSkipLogicCondition[index],
            newSkipLogicCondition[item.index],
          ];
          setSkipLogicCondition(newSkipLogicCondition);

          const newDisplayLogicCondition = [...displayLogicCondition];
          [
            newDisplayLogicCondition[item.index],
            newDisplayLogicCondition[index],
          ] = [
            newDisplayLogicCondition[index],
            newDisplayLogicCondition[item.index],
          ];
          setDisplayLogicCondition(newDisplayLogicCondition);

          item.index = index;
        }
      },
    });
    return (
      <Box
        ref={(node) => drag(drop(node))}
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "space-between",
          border: "1px solid #000",
          borderRadius: "10px",
          padding: "10px",
          gap: "30px",
          width: "100%",
          marginBottom: "10px",
          backgroundColor: selectedForm.index === index ? "#912828" : "white",
          cursor: "move",
        }}
        onClick={() => setSelectedForm({ type: form.type, index })}
      >
        <div
          style={{
            display: "flex",
            gap: "5px",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "black",
              padding: "4px",
              borderRadius: "50%",
              color: "white",
              width: "30px",
              height: "30px",
            }}
          >
            {index + 1}
          </div>
          <Typography
            style={{
              border: "none",
              outline: "none",

              width: "100px",
              color: selectedForm.index === index ? "white" : "black",
            }}
          >
            {form && form.question && form.question.slice(0, 15) + "..."}
          </Typography>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <IconButton
            onClick={() => {
              setForms([
                ...forms.slice(0, index + 1),
                {
                  type: selectedForm.type,
                  index: forms.length,
                  ids: Date.now(),
                  ...getPayload(selectedForm.type),
                },
                ...forms.slice(index + 1),
              ]);

              setLogicQuestionSelecteData([
                ...logicQuestionSelecteData.slice(0, index + 1),
                {
                  type: selectedForm.type,
                  index: forms.length,
                  ids: Date.now(),
                  name: "",
                  value: "",
                },
                ...logicQuestionSelecteData.slice(index + 1),
              ]);

              setDisplayLogicQuestionSelecteData([
                ...displaylogicQuestionSelecteData.slice(0, index + 1),
                {
                  type: selectedForm.type,
                  index: forms.length,
                  ids: Date.now(),
                  name: "",
                  value: "",
                },
                ...displaylogicQuestionSelecteData.slice(index + 1),
              ]);

              setSkipLogicCondition([
                ...skipLogicCondition.slice(0, index + 1),
                {
                  questionIndex: "",
                  questionType: "",
                  condition: "",
                  jumpTo: "",
                },
                ...skipLogicCondition.slice(index + 1),
              ]);

              setDisplayLogicCondition([
                ...displayLogicCondition.slice(0, index + 1),
                {
                  questionIndex: "",
                  questionType: "",
                  condition: "",
                  value: "",
                },
                ...displayLogicCondition.slice(index + 1),
              ]);
            }}
          >
            <FaPlus
              size={20}
              style={{
                color: selectedForm.index === index ? "white" : "black",
              }}
            />
          </IconButton>
          <IconButton>
            <HiOutlineDuplicate
              size={20}
              style={{
                color: selectedForm.index === index ? "white" : "black",
              }}
              onClick={() => {
                //when click on duplicate then all data should be duplicate
                setForms([
                  ...forms.slice(0, index + 1),
                  {
                    ...forms[index],
                    index: forms.length,
                    ids: Date.now(),
                  },
                  ...forms.slice(index + 1),
                ]);

                setLogicQuestionSelecteData([
                  ...logicQuestionSelecteData.slice(0, index + 1),
                  {
                    ...logicQuestionSelecteData[index],
                    index: forms.length,
                    ids: Date.now(),
                  },
                  ...logicQuestionSelecteData.slice(index + 1),
                ]);
                setDisplayLogicQuestionSelecteData([
                  ...displaylogicQuestionSelecteData.slice(0, index + 1),
                  {
                    ...displaylogicQuestionSelecteData[index],
                    index: forms.length,
                    ids: Date.now(),
                  },
                  ...displaylogicQuestionSelecteData.slice(index + 1),
                ]);

                setSkipLogicCondition([
                  ...skipLogicCondition.slice(0, index + 1),
                  {
                    ...skipLogicCondition[index],
                  },
                  ...skipLogicCondition.slice(index + 1),
                ]);

                setDisplayLogicCondition([
                  ...displayLogicCondition.slice(0, index + 1),
                  {
                    ...displayLogicCondition[index],
                  },
                  ...displayLogicCondition.slice(index + 1),
                ]);
              }}
            />
          </IconButton>
          <IconButton>
            <BsTrash
              size={18}
              style={{
                color: selectedForm.index === index ? "white" : "black",
              }}
              onClick={() => {
                //delete index
                setForms(forms.filter((form, index1) => index1 !== index));

                setLogicQuestionSelecteData(
                  logicQuestionSelecteData.filter(
                    (form, index1) => index1 !== index
                  )
                );
                setDisplayLogicQuestionSelecteData(
                  displaylogicQuestionSelecteData.filter(
                    (form, index1) => index1 !== index
                  )
                );
                setSkipLogicCondition(
                  skipLogicCondition.filter((form, index1) => index1 !== index)
                );
                setDisplayLogicCondition(
                  displayLogicCondition.filter(
                    (form, index1) => index1 !== index
                  )
                );
              }}
            />
          </IconButton>
        </div>
      </Box>
    );
  };
  const [selectform, setSelectform] = React.useState("");

  const handleChange = (e) => {
    if (e.target.value === "") {
      return;
    }
    handleAddForm(e.target.value);
  };

  const getFormDetail = async (id) => {
    let res = await formDetail(id);
    if (res?.status) {
      formik.setValues({
        title: res.data.title,
        expireDate: moment(res.data.expireDate).format("YYYY-MM-DD"),
      });
      setDisplayLogicCondition(JSON.parse(res.data.displayLogicCondition));
      setSkipLogicCondition(JSON.parse(res.data.skipLogicCondition));
      setDisplayLogicQuestionSelecteData(JSON.parse(res.data.displayLogic));
      setLogicQuestionSelecteData(JSON.parse(res.data.skipLogic));
      setForms(JSON.parse(res.data.questions));
    }
  };

  return (
    <>
      <Heading head={editId ? "Form Edit" : "Form Creation"} />
      <Stack
        flexDirection={"row"}
        alignItems={"center"}
        gap={1}
        justifyContent={"space-between"}
      >
        <Grid
          gap={2}
          sx={{ display: "flex", flexDirection: "row", marginTop: "10px" }}
        >
          <Grid item xs={6} style={{ width: "300px" }}>
            <Input labelinput={"Form Name"} name="title" formik={formik} />
          </Grid>
          <Grid item xs={6}>
            <Input
              labelinput={"Expires Date"}
              name="expireDate"
              type="date"
              formik={formik}
              isDate={true}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            // width: "300px",
            gap: "10px",
            display: "flex",
            marginTop: "10px",
          }}
        >
          <Button
            buttonName="Cancel"
            color="white"
            onClick={() => Navigate(`/form-management?${encryptedValue}`)}
          />
          <Button buttonName="Save" onClick={formik.handleSubmit} />
        </Grid>
      </Stack>
      <Stack
        gap={2}
        mt={1}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"start"}
      >
        <Box>
          {forms.map((form, index) => (
            <DraggableForm
              key={form.ids}
              form={form}
              index={index}
              selectedForm={selectedForm}
              setSelectedForm={setSelectedForm}
              setShowButtons={setShowButtons}
              forms={forms}
              setForms={setForms}
            />
          ))}

          <Box>
            <FormControl>
              <Select
                value={selectform}
                onChange={handleChange}
                displayEmpty
                sx={{
                  width: 300,
                  ".MuiSelect-select": {
                    padding: "10px",
                  },
                }}
              >
                <MenuItem value="" sx={{ display: "flex", gap: "10px" }}>
                  Create New Question Type
                </MenuItem>
                <MenuItem value="text" sx={{ display: "flex", gap: "10px" }}>
                  Text
                </MenuItem>
                <MenuItem
                  value="multiSelect"
                  sx={{ display: "flex", gap: "10px" }}
                >
                  {" "}
                  Multiple Choice
                </MenuItem>
                <MenuItem value="scale" sx={{ display: "flex", gap: "10px" }}>
                  {" "}
                  Scale
                </MenuItem>
                <MenuItem
                  value="DropDown"
                  sx={{ display: "flex", gap: "10px" }}
                >
                  {" "}
                  Drop Down
                </MenuItem>
                <MenuItem value="Metrix" sx={{ display: "flex", gap: "10px" }}>
                  {" "}
                  Metrix
                </MenuItem>
                <MenuItem value="Scan" sx={{ display: "flex", gap: "10px" }}>
                  Media Capture
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Stack
          mb={1}
          gap={2}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {formChange}
        </Stack>
      </Stack>
      {forms.length > 0 && (
        <Grid
          item
          xs={6}
          style={{
            gap: "10px",
            display: "flex",
            marginTop: "10px",
            justifyContent: "end",
            textAlign: "right",
          }}
        >
          <Button
            buttonName="Cancel"
            color="white"
            onClick={() => Navigate(`/form-management?${encryptedValue}`)}
          />
          <Button buttonName="Save" onClick={formik.handleSubmit} />
        </Grid>
      )}
      <Modal
        open={showButtons.show}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        {/* {showButtons && ( */}

        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -20%)",
            backgroundColor: "white.main",
            borderRadius: "7px",
            border: "none",
            padding: "20px",
            width: "400px",
            flexWrap: "wrap",
            overflow: "scroll",
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "20px",
              fontWeight: "800",
              marginBottom: "10px",
            }}
          >
            Create New Question Type
          </Typography>
          <Stack
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gridTemplateRows: "repeat(3, 1fr)",
              gridColumnGap: "37px",
              gridRowGap: "24px",
            }}
          >
            <Button
              buttonName={"Text"}
              size="small"
              onClick={() => handleAddForm("text")}
              style={{ width: "100%" }}
            >
              Text
            </Button>

            <Button
              buttonName={"Multiple Choice"}
              size="small"
              onClick={() => handleAddForm("multiSelect")}
              style={{ width: "100%" }}
            >
              Multiple Choice
            </Button>

            <Button
              buttonName={"Scale"}
              size="small"
              style={{ width: "100%" }}
              onClick={() => handleAddForm("scale")}
            >
              Scale
            </Button>

            <Button
              buttonName={"Drop Down"}
              size="small"
              style={{ width: "100%" }}
              onClick={() => handleAddForm("DropDown")}
            >
              Drop Down
            </Button>

            <Button
              buttonName={"Metrix"}
              size="small"
              style={{ width: "100%" }}
              onClick={() => handleAddForm("Metrix")}
            >
              Metrix
            </Button>
            <Button
              buttonName={"Scan"}
              size="small"
              style={{ width: "100%" }}
              onClick={() => handleAddForm("Scan")}
            >
              Media Capture
            </Button>
          </Stack>
          {/* </Stack> */}
        </Box>
        {/* )} */}
      </Modal>
    </>
  );
};

export default FormCreation;
