import React, { useEffect, useState } from "react";
import { Button, Heading, Modal } from "../../components/Common";
import { Search } from "../../components/Common/Table";
import { VscFilter } from "react-icons/vsc";
import { useNavigate } from "react-router-dom";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import {
    copyForm,
    exportForm,
    extendForm,
    formAssign,
    formDelete,
    formStatusChange,
    surveyFormList,
} from "../../Service/form.service";
import { GroupDropdown } from "../../Service/group.service";
import { saveAs } from "file-saver";

import {
    IconButton,
    Paper,
    Stack,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from "@mui/material";
import { BiSolidEdit } from "react-icons/bi";
import { GoClock } from "react-icons/go";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { HiOutlineDuplicate, HiOutlineTrash } from "react-icons/hi";
import FilterForm from "./FilterForm";
import { MdOutlineAssignment } from "react-icons/md";
import moment from "moment";
import Pagination from "../../components/Common/Table/Pagination";
import { notificationSuccess } from "../../state/Action/NotificationAction";
import { useDispatch } from "react-redux";
import {
    formFilterInitialValues,
    assignFormInitialValues,
    extendFormInitialValues,
} from "../../helper/initialValues";
import { useFormik } from "formik";
import {
    extendFormValidationSchema,
    formAssignValidationSchema,
} from "../../helper/validation";
import TableRowsLoader from "../../components/Common/Loader/Skeleton";
import CryptoJS from "crypto-js";
import { TiExportOutline } from "react-icons/ti";
import { IsDelete, IsWrite } from "../../helper/handlePermission";

const FormDownload = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const secretKey = "your-secret-key"; // Use a secure key

    const editPermissions = IsWrite("From Management");
    const deletePermissions = IsDelete("From Management");

    const [isModalOpen, setIsModalOpen] = useState({
        open: false,
        currentComponent: "",
        para: "",
        head: "",
    });
    const [search, setSearch] = useState("");
    const [csvData, setCsvData] = useState([]);
    const [value, setValue] = useState("");
    const [page, setPage] = React.useState(0);
    const [openDrawer, setOpenDrawer] = React.useState(false);
    const [formData, setFormData] = useState([]);
    const [totalData, setTotalData] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [groupData, setGroupData] = useState([]);
    const [sortConfig, setSortConfig] = useState({
        key: "",
        direction: "",
    });

    const encryptUrlParams = (params) => {
        const encrypted = CryptoJS.AES.encrypt(
            JSON.stringify(params),
            secretKey
        ).toString();
        return encrypted;
    };
    const toggleDrawer = () => {
        setOpenDrawer(!openDrawer);
    };

    useEffect(() => {
        getFormData(page, search);
    }, [page, search, sortConfig]);

    useEffect(() => {
        getGroupDropdown();
    }, []);

    const formik1 = useFormik({
        initialValues: formFilterInitialValues,
        onSubmit: async (values) => {
            setOpenDrawer(false);
            getFormData(page, search);
        },
    });

    const formik = useFormik({
        initialValues:
            isModalOpen.currentComponent === "assignForm"
                ? assignFormInitialValues
                : extendFormInitialValues,
        validationSchema:
            isModalOpen.currentComponent === "assignForm"
                ? formAssignValidationSchema
                : extendFormValidationSchema,
        onSubmit: async (values) => {
            let payload = {};

            switch (isModalOpen.currentComponent) {
                case "assignForm":
                    payload = { groups: values.groups };
                    break;
                case "extend":
                    payload = { expiresDate: values.extendDate };
                    break;
                default:
                    payload = null;
            }

            let res;

            if (payload) {
                res = await (isModalOpen.currentComponent === "assignForm"
                    ? formAssign(isModalOpen.id, payload)
                    : extendForm(isModalOpen.id, payload));
            } else {
                res = null;
            }

            if (res?.status) {
                dispatch(
                    notificationSuccess({
                        show: true,
                        type: "success",
                        message: res.message || "Success",
                    })
                );
                setIsModalOpen({
                    open: false,
                    currentComponent: "",
                    para: "",
                    head: "",
                    isEdit: false,
                    isView: false,
                });

                formik.resetForm();
                getFormData(page, search);
            } else {
                dispatch(
                    notificationSuccess({
                        show: true,
                        type: "error",
                        message:
                            res.message ?? "Something went wrong. Please try again later.",
                    })
                );
            }
        },
    });

    console.log("FormManagement", formik.values);

    const getGroupDropdown = async () => {
        const res = await GroupDropdown();
        if (res.status) {
            setGroupData(res.data);
        } else {
            setGroupData([]);
        }
    };

    const getFormData = async (page, search) => {
        setFormData([]);

        let selectQry = `?page=${page + 1
            }&limit=10&search=${search}&fromExpireDate=${formik1.values.fromExpireDate
            }&toExpireDate=${formik1.values.toExpireDate}&fromPublishedDate=${formik1.values.fromPublishedDate
            }&toPublishedDate=${formik1.values.toPublishedDate}&status=${formik1.values.status
            }&sortBy=${sortConfig.key}&sortFlag=${sortConfig.direction}`;
        const res = await surveyFormList(selectQry);
        if (res.status) {
            setFormData(res.data);
            setTotalData(res.total);
        } else {
            setFormData(null);
        }
    };

    const changeStatus = async () => {
        const res =
            isModalOpen.type === "status"
                ? await formStatusChange(isModalOpen.id)
                : isModalOpen.type === "delete"
                    ? await formDelete(isModalOpen.id)
                    : isModalOpen.type === "copy"
                        ? await copyForm(isModalOpen.id)
                        : isModalOpen.type === "export"
                            ? await exportForm(isModalOpen.id)
                            : null;

        if (res?.status) {
            dispatch(
                notificationSuccess({
                    show: true,
                    type: "success",
                    message: res.message || "Success",
                })
            );
            // let data
            if (isModalOpen.type === "export") {
                let data = [res?.data.questions, ...res?.data.values];
                // console.log("datcxdcxcxcxcxa", data);
                // setCsvData(res.data);
                //replace , to .
                // data = data.map((item) => {
                //   return item.map((i) => {
                //     if (typeof i === "string") {
                //       return i.replace(/,/g, ".");
                //     } else {
                //       return i;
                //     }
                //   });
                // });
                // Convert array of arrays into CSV string
                // const csvContent = data.map((e) => e.join(",")).join("\n");
                console.log("data", data);
                const csvContent = data
                    .map((row) =>
                        row
                            .map((value) =>
                                value === null || value === "" ? "" : `"${value}"`
                            )
                            .join(",")
                    )
                    .join("\n");

                console.log("csvContent", csvContent);

                const blob = new Blob([csvContent], {
                    type: "text/csv;charset=utf-8;",
                });

                saveAs(blob, "FormData.csv");
            }
            getFormData(page, search);
            setIsModalOpen({
                open: false,
                currentComponent: "",
                para: "",
                head: "",
                id: "",
            });
        } else {
            dispatch(
                notificationSuccess({
                    show: true,
                    type: "error",
                    message:
                        res.message || "Something went wrong. Please try again later.",
                })
            );
        }
    };

    const params = {
        page: page + 1,
        search: search,
    };

    return (
        <>
            <Heading head={"Form Download"} />
            <Stack gap={2} mt={1}>
                <Stack
                    mb={1}
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                >
                    <Search search={search} setSearch={setSearch} setPage={setPage} />
                    <Stack flexDirection={"row"} alignItems={"center"} gap={1}>

                        <Button
                            buttonName={<VscFilter />}
                            style={{ padding: "10px 11px" }}
                            color="white"
                            size="small"
                            onClick={toggleDrawer}
                            variant="contained"
                            sx={{ mt: 2 }}
                        />
                        <FilterForm
                            value={value}
                            setValue={setValue}
                            openDrawer={openDrawer}
                            toggleDrawer={toggleDrawer}
                            formik={formik1}
                        />
                    </Stack>
                </Stack>
                <TableContainer component={Paper}>
                    <Table stickyHeader aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ cursor: "pointer" }}>
                                    <Stack
                                        flexDirection={"row"}
                                        gap={2}
                                        alignItems={"center"}
                                        justifyContent={"start"}
                                    >
                                        <div>{"Sr No."}</div>
                                    </Stack>
                                </TableCell>
                                <TableCell style={{ cursor: "pointer" }}>
                                    <Stack
                                        flexDirection={"row"}
                                        gap={2}
                                        alignItems={"center"}
                                        justifyContent={"start"}
                                    >
                                        <div>Name</div>
                                    </Stack>
                                </TableCell>
                                <TableCell
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                        setSortConfig({
                                            key: "createdAt",
                                            direction:
                                                sortConfig &&
                                                    sortConfig.key === "createdAt" &&
                                                    sortConfig.direction === "asc"
                                                    ? "desc"
                                                    : "asc",
                                        })
                                    }
                                >
                                    <Stack
                                        flexDirection={"row"}
                                        gap={2}
                                        alignItems={"center"}
                                        justifyContent={"start"}
                                    >
                                        <div>Created Date</div>
                                        {sortConfig &&
                                            sortConfig.key === "createdAt" &&
                                            sortConfig.direction === "asc" ? (
                                            <IoIosArrowUp className="iconstyle" />
                                        ) : (
                                            <IoIosArrowDown />
                                        )}
                                    </Stack>
                                </TableCell>
                                <TableCell
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                        setSortConfig({
                                            key: "publishedDate",
                                            direction:
                                                sortConfig &&
                                                    sortConfig.key === "publishedDate" &&
                                                    sortConfig.direction === "asc"
                                                    ? "desc"
                                                    : "asc",
                                        })
                                    }
                                >
                                    <Stack
                                        flexDirection={"row"}
                                        gap={2}
                                        alignItems={"center"}
                                        justifyContent={"start"}
                                    >
                                        <div>Published</div>
                                        {sortConfig &&
                                            sortConfig.key === "publishedDate" &&
                                            sortConfig.direction === "asc" ? (
                                            <IoIosArrowUp className="iconstyle" />
                                        ) : (
                                            <IoIosArrowDown />
                                        )}
                                    </Stack>
                                </TableCell>
                                <TableCell
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                        setSortConfig({
                                            key: "expiresDate",
                                            direction:
                                                sortConfig &&
                                                    sortConfig.key === "expiresDate" &&
                                                    sortConfig.direction === "asc"
                                                    ? "desc"
                                                    : "asc",
                                        })
                                    }
                                >
                                    <Stack
                                        flexDirection={"row"}
                                        gap={2}
                                        alignItems={"center"}
                                        justifyContent={"start"}
                                    >
                                        <div>Expires</div>
                                        {sortConfig &&
                                            sortConfig.key === "expiresDate" &&
                                            sortConfig.direction === "asc" ? (
                                            <IoIosArrowUp className="iconstyle" />
                                        ) : (
                                            <IoIosArrowDown />
                                        )}
                                    </Stack>
                                </TableCell>
                                <TableCell style={{ cursor: "pointer" }}>
                                    <Stack
                                        flexDirection={"row"}
                                        gap={2}
                                        alignItems={"center"}
                                        justifyContent={"center"}
                                    >
                                        <div>Export</div>
                                    </Stack>
                                </TableCell>
                                <TableCell style={{ cursor: "pointer" }}>
                                    <Stack
                                        flexDirection={"row"}
                                        gap={2}
                                        alignItems={"center"}
                                        justifyContent={"start"}
                                    >
                                        <div>Result</div>
                                    </Stack>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {formData &&
                                formData?.map((data) => (
                                    <TableRow
                                        key={data.id}
                                        sx={{
                                            "&:last-child td, &:last-child th": { border: 0 },
                                            cursor: "pointer",
                                        }}
                                    >
                                        <TableCell
                                            className="table-hover"
                                            style={{ textAlign: "left" }}
                                        >
                                            {page * 10 + formData.indexOf(data) + 1}
                                        </TableCell>

                                        <TableCell style={{ textAlign: "left" }}>
                                            {data.title}
                                        </TableCell>

                                        <TableCell style={{ textAlign: "left" }}>
                                            {moment(data.createdAt).format("DD-MM-YYYY")}
                                        </TableCell>

                                        <TableCell style={{ textAlign: "left" }}>
                                            {moment(data.publishedDate).format("DD-MM-YYYY")}
                                        </TableCell>

                                        <TableCell style={{ textAlign: "left" }}>
                                            {moment(data.expiresDate).format("DD-MM-YYYY")}
                                        </TableCell>

                                        <TableCell style={{ textAlign: "center" }}>
                                            {editPermissions || deletePermissions ? (
                                                <>
                                                    {editPermissions && (
                                                        <>


                                                            <Tooltip title="Export Data">
                                                                <IconButton>
                                                                    <TiExportOutline
                                                                        size={23}
                                                                        style={{
                                                                            backgroundColor: "white",
                                                                            padding: "5px",
                                                                            color: "black",
                                                                            borderRadius: "50%",
                                                                        }}
                                                                        onClick={() => {
                                                                            setIsModalOpen({
                                                                                open: true,
                                                                                currentComponent: "delete",
                                                                                id: data?._id,
                                                                                type: "export",
                                                                                head: "Export Data",
                                                                                para: "Are you sure you want to export this form?",
                                                                            });
                                                                        }}
                                                                    />
                                                                </IconButton>
                                                            </Tooltip>

                                                            {/* //copy data  */}

                                                        </>
                                                    )}
                                                </>
                                            ) : (
                                                "-"
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            <DropdownMenu.Root>
                                                <DropdownMenu.Trigger className="chatDropDown">
                                                    <Button
                                                        buttonName={data.submissionCount}
                                                        color="white"
                                                        style={{ width: "100%" }}
                                                    />
                                                </DropdownMenu.Trigger>
                                            </DropdownMenu.Root>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            {formData == null ? (
                                <TableRow>
                                    <TableCell
                                        sx={{
                                            color: "silver",
                                            textAlign: "center",
                                            paddingTop: "90px",
                                            borderBottom: "none",
                                            fontSize: "30px",
                                        }}
                                        colSpan="6"
                                    >
                                        No records to display
                                    </TableCell>
                                </TableRow>
                            ) : formData && formData.length == 0 ? (
                                <TableRowsLoader colNumber={8} rowsNum={10} />
                            ) : (
                                ""
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Pagination
                        count={10}
                        shape="rounded"
                        totalData={totalData}
                        page={page}
                        setPage={setPage}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                    />
                </div>
            </Stack>
            <Modal
                modalOpen={isModalOpen}
                formik={formik}
                handleClose={() => {
                    setIsModalOpen({ open: false, currentComponent: "", id: "" });
                    formik.resetForm();
                }}
                data={groupData}
                handelClick={changeStatus}
            />
        </>
    );
};

export default FormDownload;
