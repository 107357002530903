import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Paper,
  Popover,
  Select,
  Radio,
  RadioGroup,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useRef } from "react";
import { Button, Input } from "../../../components/Common";
import { IoIosAdd } from "react-icons/io";
import { IoRemoveOutline } from "react-icons/io5";
import { v4 as uuidv4 } from "uuid";
import { HiOutlineTrash } from "react-icons/hi";
import Papa from "papaparse";
import { notificationSuccess } from "../../../state/Action/NotificationAction";
import { useDispatch } from "react-redux";

const MetrixTypeForm = ({ index, forms, setForms, formIndex }) => {
  const fileInputRef = useRef([]);
  const [anchorElAdd, setAnchorElAdd] = React.useState(null);
  const [anchorElRemove, setAnchorElRemove] = React.useState(null);

  const dispatch = useDispatch();
  const openAdd = Boolean(anchorElAdd);
  const openRemove = Boolean(anchorElRemove);
  const idAdd = openAdd ? "add-popover" : undefined;
  const idRemove = openRemove ? "remove-popover" : undefined;

  const randomId = () => {
    return uuidv4();
  };

  const handleAddRating = (indexs) => {
    let newArray = [...forms];

    // Ensure dataArray is initialized as an array
    if (!Array.isArray(newArray[index].metrixData[0][indexs].dataArray)) {
      newArray[index].metrixData[indexs].dataArray = [];
    }

    console.log(
      "newArray[index].metrixData[indexs].dataArray",
      newArray[index].metrixData[0][indexs].dataArray
    );

    // Add a new object to dataArray
    newArray[index].metrixData[0][indexs].dataArray = [
      ...newArray[index].metrixData[0][indexs].dataArray,
      { ids: randomId(), value: "", disable: true },
    ];

    setForms(newArray);
  };

  const handleDeleteRating = (id, i) => {
    let newArray = [...forms];
    newArray[index].metrixData[0][i].dataArray = newArray[index].metrixData[0][
      i
    ].dataArray.filter((rating) => rating.ids !== id);
    if (newArray[index].metrixData[0][i].dataArray.length === 0) {
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      newArray[index].metrixData[0][i].dataArray.file = "";
    }
    setForms(newArray);
  };
  const handleRating = (id, value, index, indexs) => {
    // Create a copy of the forms array
    let newArray = [...forms];

    console.log("newArraysdadsdsdsd", newArray[formIndex]);

    // Ensure that the index exists in newArray
    if (
      newArray[formIndex] &&
      newArray[formIndex].metrixData &&
      newArray[formIndex].metrixData[0][index]
    ) {
      // Check if dataArray exists at the expected location
      if (newArray[formIndex].metrixData[0][index].dataArray) {
        newArray[formIndex].metrixData[0][index].dataArray = newArray[
          formIndex
        ].metrixData[0][index].dataArray.map((rating) =>
          rating.ids === id ? { ...rating, value } : rating
        );

        setForms(newArray);
      } else {
        console.error("dataArray is undefined at the given indexs");
      }
    } else {
      console.error("Invalid index or data path does not exist");
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    Papa.parse(file, {
      complete: (results) => {
        const [header, ...data] = results.data;
        let newArray = [...forms];

        // Iterate over each column header from the file
        header.forEach((fileHeader, fileIndex) => {
          const fileHeaderTrimmed = fileHeader.trim();
          const columnIndex = forms[index].metrixData[0].findIndex(
            (column) => column.value === fileHeaderTrimmed
          );
          console.log("fileHeaderTrimmed", columnIndex);

          if (columnIndex !== -1) {
            // Column header matched
            // const parsedData = data.map((row) => ({
            //   ids: uuidv4(),
            //   value: row[fileIndex] ? row[fileIndex].trim() : "",
            //   file: true,
            // }));
            const parsedData = data.map((row) => {
              if (row[fileIndex] !== "") {
                return {
                  ids: uuidv4(),
                  value: row[fileIndex] ? row[fileIndex].trim() : "",
                  file: true,
                };
              } else {
                return null;
              }
            });
            let newData = parsedData.filter(
              (data) => data !== null && data !== ""
            );

            if (newArray[index].metrixData[0][columnIndex].dataArray) {
              newArray[index].metrixData[0][columnIndex].dataArray = newData;
            } else {
              // newArray[index].metrixData[0][columnIndex].dataArray = [
              //   ...newArray[index].metrixData[0][columnIndex].dataArray,
              //   ...parsedData,
              // ];
              newArray[index].metrixData[0][columnIndex].dataArray = newData;
            }
          } else {
            // // if (fileIndex === header.length - 1) {
            // dispatch(notificationSuccess({
            //   show: true,
            //   type: 'error',
            //   message: `Header ${[...notMatched, fileHeaderTrimmed].join(", ")} does not match any existing column`
            // }))
            console.error(
              `Header ${fileHeaderTrimmed} does not match any existing column`
            );
          }
        });

        setForms(newArray);
      },
      header: false,
    });

    let newArray = [...forms];
    newArray[formIndex].file = file.name;
    setForms(newArray);
  };

  const handleFileRemove = (i) => {
    let newArray = [...forms];
    newArray[index].file = "";

    if (newArray?.[index]?.metrixData?.[0]) {
      newArray[index].metrixData[0].forEach((row, rowIndex) => {
        if (row.dataArray) {
          newArray[index].metrixData[0][rowIndex].dataArray =
            row.dataArray.filter((answer) => !answer.file);
        }
      });
    }
    setForms(newArray);

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleClickAdd = (event) => {
    setAnchorElAdd(event.currentTarget);
  };

  const handleClickRemove = (event) => {
    setAnchorElRemove(event.currentTarget);
  };

  const handleCloseAdd = () => {
    setAnchorElAdd(null);
  };

  const handleCloseRemove = () => {
    setAnchorElRemove(null);
  };

  const addColumn = () => {
    let newArray = [...forms];
    newArray[index].metrixData.map((row) =>
      row.push({ type: "text", dataArray: [], value: "", disable: true })
    );
    setForms(newArray);
  };

  const removeColumn = () => {
    if (forms[index].metrixData[0].length > 1) {
      let newArray = [...forms];
      newArray[index].metrixData.map((row) => row.pop());
      setForms(newArray);
    }
  };

  const handleInputChange = (rowIndex, colIndex, value) => {
    const updatedTable = forms[index].metrixData.map((row, rIndex) =>
      row.map((cell, cIndex) =>
        rIndex === rowIndex && cIndex === colIndex
          ? { ...cell, value: value }
          : cell
      )
    );
    let newArray = [...forms];
    newArray[index].metrixData = updatedTable;
    setForms(newArray);
  };

  const handleInputTypeChange = (rowIndex, colIndex, type) => {
    const updatedTable = forms[index].metrixData.map((row, rIndex) =>
      row.map((cell, cIndex) =>
        rIndex === rowIndex && cIndex === colIndex
          ? { ...cell, type: type }
          : cell
      )
    );
    let newArray = [...forms];
    newArray[index].metrixData = updatedTable;
    setForms(newArray);
  };

  return (
    <>
      <label style={{ display: "flex", alignItems: "center" }}>
        <input
          type="checkbox"
          style={{
            marginRight: "10px",
            height: "17px",
            width: "17px",
          }}
          onChange={(e) => {
            let newArray = [...forms];
            newArray[formIndex].autoIncrement = e.target.checked;
            setForms(newArray);
          }}
          checked={forms[formIndex].autoIncrement}
        />
        Autoincrement Row
      </label>
      <Stack
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "10px",
          flexDirection: "row",
          width: "100%",
          marginBottom: "20px",
        }}
      >
        <Stack sx={{ width: "100%" }}>
          <Stack
            sx={{
              backgroundColor: "white.main",
              borderRadius: "7px",
              padding: "20px",
              paddingTop: "0 !important",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              height: "fit-content",
              maxWidth: "100%",
            }}
          >
            <Stack
              sx={{
                display: "flex",
                gap: "10px",
                flexDirection: "col",
                maxWidth: "1000px",
              }}
            >
              <Stack
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  flexDirection: "row",
                }}
              >
                <div>
                  <IconButton
                    aria-describedby={idAdd}
                    variant="contained"
                    onClick={handleClickAdd}
                  >
                    <IoIosAdd
                      size={23}
                      style={{
                        backgroundColor: "green",
                        padding: "2px",
                        color: "white",
                        borderRadius: "50%",
                      }}
                    />
                  </IconButton>
                  <Popover
                    id={idAdd}
                    open={openAdd}
                    anchorEl={anchorElAdd}
                    onClose={handleCloseAdd}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <Stack>
                      <Button
                        buttonName={"Add Column"}
                        color="white"
                        style={{ width: "100%", border: "none" }}
                        onClick={addColumn}
                      />
                    </Stack>
                  </Popover>
                </div>
                {forms?.[index]?.metrixData?.[0]?.length > 1 && (
                  <div>
                    <IconButton
                      aria-describedby={idRemove}
                      variant="contained"
                      onClick={handleClickRemove}
                    >
                      <IoRemoveOutline
                        size={23}
                        style={{
                          backgroundColor: "green",
                          padding: "2px",
                          color: "white",
                          borderRadius: "50%",
                        }}
                      />
                    </IconButton>
                    <Popover
                      id={idRemove}
                      open={openRemove}
                      anchorEl={anchorElRemove}
                      onClose={handleCloseRemove}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <Stack>
                        <Button
                          buttonName="Delete Column"
                          color="white"
                          style={{ width: "100%", border: "none" }}
                          onClick={removeColumn}
                        />
                      </Stack>
                    </Popover>
                  </div>
                )}
              </Stack>
              <TableContainer component={Paper}>
                <Table stickyHeader aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {forms?.[index]?.metrixData?.[0]?.map(
                        (cell, colIndex) => (
                          <TableCell
                            style={{ borderRightWidth: "2px" }}
                            key={colIndex}
                          >
                            <Stack
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <input
                                type="text"
                                style={{
                                  outline: "none",
                                  border: "none",
                                  backgroundColor: "transparent",
                                  width: "100%",
                                }}
                                placeholder="Enter Column Name"
                                value={cell.value}
                                onChange={(e) =>
                                  handleInputChange(0, colIndex, e.target.value)
                                }
                              />
                            </Stack>
                          </TableCell>
                        )
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      key={1}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        cursor: "pointer",
                      }}
                    >
                      {forms?.[index]?.metrixData?.[0]?.map(
                        (cell, colIndex) => (
                          <TableCell
                            key={colIndex}
                            style={{ borderRightWidth: "2px" }}
                          >
                            <FormControl sx={{ display: "flex" }}>
                              <Select
                                id={`select-${colIndex}`}
                                displayEmpty
                                onChange={(e) => {
                                  const newArray = [...forms];
                                  newArray[index].metrixData[0][
                                    colIndex
                                  ].dataArray = [];
                                  setForms(newArray);
                                  handleInputTypeChange(
                                    0,
                                    colIndex,
                                    e.target.value
                                  );
                                }}
                                value={cell.type}
                              >
                                <MenuItem value="single">Radio Button</MenuItem>
                                <MenuItem value="multiple">Check Box</MenuItem>
                                <MenuItem value="text">Text Box</MenuItem>
                                <MenuItem value="ratting">Rating</MenuItem>
                                <MenuItem value="selectbox">
                                  Select Box
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </TableCell>
                        )
                      )}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
            {forms?.[index]?.metrixData?.[0]?.length > 0 &&
              forms[index].metrixData[0].filter(
                (data, INDEX) => data?.type == "selectbox"
              )?.length > 0 && (
                <Stack
                  sx={{
                    backgroundColor: "white.main",
                    borderRadius: "7px",
                    border: "1px solid #E0E0E0",
                    shadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    padding: "20px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    height: "fit-content",
                  }}
                >
                  <Stack
                    sx={{ display: "flex", flexDirection: "row", gap: "50px" }}
                  >
                    <Typography
                      fontSize={{ xs: "10px", sm: "16px" }}
                      fontWeight={500}
                      mb={"2px"}
                    >
                      Enter Bulk Option Data :-{" "}
                    </Typography>
                    {forms[formIndex].file == "" && (
                      <Button
                        buttonName={"Choose File"}
                        onClick={() => fileInputRef.current.click()}
                      />
                    )}

                    <input
                      type="file"
                      onChange={(e) => {
                        handleFileChange(e);
                      }}
                      accept=".csv"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                    />
                    {forms[formIndex].file && (
                      <Typography
                        fontSize={{ xs: "10px", sm: "16px" }}
                        fontWeight={500}
                        display={"flex"}
                        flexDirection={"column"}
                        width={
                          forms[formIndex].file.length > 20
                            ? `${forms[formIndex].file.length * 10}px`
                            : "100%"
                        }
                      >
                        {forms[formIndex].file}
                      </Typography>
                    )}
                    {forms[index].file && (
                      <Button
                        buttonName={"Remove File"}
                        onClick={() => {
                          handleFileRemove(1);
                        }}
                        style={{ backgroundColor: "red", color: "white" }}
                      />
                    )}
                  </Stack>
                  <Divider />
                  <Divider />
                </Stack>
              )}
            <div className="metrixgrid">
              {forms?.[index]?.metrixData?.[0]?.length > 0 &&
                forms[index].metrixData[0].map((data, INDEX) =>
                  data?.type === "selectbox" ? (
                    <Stack
                      key={INDEX}
                      sx={{
                        backgroundColor: "white.main",
                        borderRadius: "7px",
                        border: "1px solid #E0E0E0",
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                        padding: "20px",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        height: "fit-content",
                      }}
                    >
                      <Stack
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "50px",
                        }}
                      >
                        <Typography
                          fontSize={{ xs: "10px", sm: "16px" }}
                          fontWeight={500}
                          mb={"2px"}
                        >
                          Scale Point {INDEX + 1}
                        </Typography>

                        {/* Uncomment and use these if needed
                            <input
                              type="file"
                              onChange={(e) => handleFileChange(e, INDEX)}
                              id={`file${INDEX}`}
                              accept=".csv"
                              ref={(ref) => (fileInputRef.current[INDEX] = ref)}
                            />
                            {forms[index].data[0][INDEX].file && (
                              <Button
                                buttonName={"Remove File"}
                                onClick={() => handleFileRemove(INDEX)}
                                style={{ backgroundColor: "red", color: "white" }}
                              />
                            )} */}
                      </Stack>
                      <Divider />

                      <Box sx={{ width: "100%" }}>
                        {forms[formIndex].file != "" &&
                          data?.dataArray?.length > 0 && (
                            <Typography>
                              Total Rows : {data?.dataArray?.length}
                            </Typography>
                          )}
                        {/* // data.dataArray.map((ratings, indexs) => (
                          //   <Box
                          //     key={ratings.ids}
                          //     sx={{
                          //       display: "flex",
                          //       alignItems: "center",
                          //       marginBottom: "10px",
                          //       gap: "10px",
                          //     }}
                          //   >
                          //     <Box sx={{ flex: 1 }}>
                          //       <Input
                          //         placeholder={`Enter Option ${indexs + 1}`}
                          //         value={ratings.value}
                          //         onChange={(e) =>
                          //           handleRating(
                          //             ratings.ids,
                          //             e.target.value,
                          //             INDEX,
                          //             indexs
                          //           )
                          //         }
                          //         sx={{ width: "100%" }}
                          //       />
                          //     </Box>
                          //     <Button
                          //       onClick={() =>
                          //         handleDeleteRating(ratings.ids, INDEX, indexs)
                          //       }
                          //       style={{
                          //         backgroundColor: "red",
                          //         color: "white",
                          //       }}
                          //       buttonName={
                          //         <HiOutlineTrash
                          //           style={{ fontSize: "15px" }}
                          //         />
                          //       }
                          //     />
                          //   </Box>
                          // ))} */}
                        {/* <Box sx={{ marginTop: "10px" }}>
                          <Button
                            onClick={() => handleAddRating(INDEX)}
                            buttonName={"Add Dropdown Option"}
                          />
                        </Box> */}
                        {forms[formIndex].file == "" && (
                          <>
                            {data.dataArray.map((ratings, indexs) => (
                              <Box
                                key={ratings.ids}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: "10px",
                                  gap: "10px",
                                }}
                              >
                                <Box sx={{ flex: 1 }}>
                                  <Input
                                    placeholder={`Enter Option ${indexs + 1}`}
                                    value={ratings.value}
                                    onChange={(e) =>
                                      handleRating(
                                        ratings.ids,
                                        e.target.value,
                                        INDEX,
                                        indexs
                                      )
                                    }
                                    sx={{ width: "100%" }}
                                  />
                                </Box>
                                <Button
                                  onClick={() =>
                                    handleDeleteRating(
                                      ratings.ids,
                                      INDEX,
                                      indexs
                                    )
                                  }
                                  style={{
                                    backgroundColor: "red",
                                    color: "white",
                                  }}
                                  buttonName={
                                    <HiOutlineTrash
                                      style={{ fontSize: "15px" }}
                                    />
                                  }
                                />
                              </Box>
                            ))}
                            <Box sx={{ marginTop: "10px" }}>
                              <Button
                                onClick={() => handleAddRating(INDEX)}
                                buttonName={"Add Dropdown Option"}
                              />
                            </Box>
                          </>
                        )}
                      </Box>
                      <Divider />
                    </Stack>
                  ) : null
                )}
            </div>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default MetrixTypeForm;
